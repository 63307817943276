// AnalyticsContext.js
import React, { createContext, useContext } from "react";
import { analytics } from "./firebaseContext";

const AnalyticsContext = createContext(null);

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export const AnalyticsProvider = ({ children }) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
