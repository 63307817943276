
import { useSelector } from 'react-redux';
import {useAnalytics} from '../AnalyticsContext'
import { logEvent } from "firebase/analytics";
import { useEffect } from 'react';

function AvisoDePrivacidad({centerTitle}) {
   
    const analytics = useAnalytics();
    const appData = useSelector((state) => state.appData);

    useEffect(()=>{
        logEvent(analytics, "L_consulta_aviso", {
            screen_name: "Hamburguesa",
        });
    },[])

    if (appData.legales && appData.legales.Aviso) {

        return (
            <div className="row contentLegal">
                <div className={`col s12 left-align ft-18 labelNavy txtBold ${centerTitle ? 'center-align':''}`}>
                    { decodeURIComponent(escape(atob( appData.legales.Aviso.titulo )))}
                </div>
                <div className="col s12 left-align ft-16 fw-400 mt-1 wrapDataLegales">
                { decodeURIComponent(escape(atob( appData.legales.Aviso.contenido )))}
                </div>
            </div>
        )

    }
    return (<div></div>)
}


export default AvisoDePrivacidad;