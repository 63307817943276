// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FIREBASE_CONFIG } from './properties';


const app = initializeApp(FIREBASE_CONFIG)
const analytics = getAnalytics(app);

export { app, analytics };
