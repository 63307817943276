import { useEffect } from 'react';
import { useAnalytics } from '../AnalyticsContext'
import { logEvent } from "firebase/analytics";

function Contacto() {

    const analytics = useAnalytics();

    useEffect(()=>{
        logEvent(analytics, "contacto", {
            screen_name: "Hamburguesa",
        });
    },[]);

    return (
        <div className="row">
            <div className="col s12 left-align txtBold ft-18">
                Contacto
            </div>
            <div className="col s12 left-align ft-16 fw-400 mt-1">
                Los horarios de atención son de Lunes a Jueves de 9:00 a 18:00 hrs y Viernes de 9:00 a 15:00 hrs. Puedes contactarnos:
            </div>
            <div className="col s12 mt-1 left-align wrapDataProfile brT brB mt-1">
                <p className="ft-16 fw-600 labelNavy ">Valmex</p>
                <p className="ft-16 fw-300 labelNavy"> <a href="tel:+525552791433" className="no_txt_deco cursorPointer labelNavy"> (+52) 55 5279 1433</a></p>
                <p className="ft-16 fw-300 labelNavy mt-2"><a href="mailto:solucionesempleados@valmex.mx" className="no_txt_deco cursorPointer labelNavy"
                    onClick={() => {

                        logEvent(analytics, "contacto_correo_vmxsi", {
                            screen_name: "Hamburguesa",
                        });
                    }}
                >solucionesempleados@valmex.mx</a></p>
            </div>

        </div>
    )
}


export default Contacto;