

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import Logo_H from '../assets/horizontal.svg'
import Logo from '../assets/logo.svg'
import { Link, useLocation, useNavigate } from "react-router-dom";
import M from 'materialize-css';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, setBitacora, toogleLoad, showModalFinSesion, setMuestraSaldoF } from "../redux/slices/appslice";
import Avatar from 'react-avatar';
import { formatFecha, encryptAES, TEXT_FIN_SESION_JWT } from '../utils'
import { BASE_URL } from "../properties";
import { getNotificaciones, getBitacora, getMostrarSaldo } from '../firestore'
import ModalTC from "./modalTCAP";
import ModalPV from "./modalPV";
import SesionInactiva from "./SesionInactiva";
import GeolocationPermissionListener from "./geolocationPermissionListener";
import {useAnalytics} from '../AnalyticsContext'
import { logEvent } from "firebase/analytics";

export const MainLayout = () => {

    const analytics = useAnalytics();
    const appData = useSelector((state) => state.appData)
    const location = useLocation()
    const dispatch = useDispatch();
    const [menuProfile, setMenuProfile] = useState(false);
    const [notificaciones, setNotificaciones] = useState([])
    const [cambioPassDirecto, setCambioPassDirecto] = useState(false);
    const navigate = useNavigate();




    const cerrarSesion = () => {

        dispatch(toogleLoad(true));
        let dataToEncrypt = {
            "Usuario": appData.user.usuarioLogin,
            "IdUsuario": `${appData.user.IdUsuario}`,
            "Sesion": appData.user.Sesion,
            "DeviceToken": "",
            "SistemaOperativo": "ios",
            "AppID": "SAF_APP",
            "token": appData.tokenSesion,
            "refresh_token": appData.token.refresh_token
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': appData.idToken
            },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
        };
        fetch(`${BASE_URL}/loggin/FinSesion`, requestOptions)
            .then(response => response.json())
            .then((data) => {
                dispatch(toogleLoad(false));
                if (data.BanderaExito) {
                    setTimeout(() => {
                        dispatch(setUser(false))
                        navigate('/login')
                    }, 300);

                } else {

                    if (data.Mensaje === "No existe sesion activa." || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                        dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
                    } else {
                        M.toast({ html: data.Mensaje, classes: 'red accent-4 white-text txtBold' })
                    }
                }
            });
    }

    const closeUserNav = () => {
        let usrNav = M.Sidenav.getInstance(document.getElementById('userNav'));
        if (usrNav && usrNav.isOpen) {
            usrNav.close()
        }
    }

    const closeUserNot = () => {
        let usrNot = M.Sidenav.getInstance(document.getElementById('userNot'));
        if (usrNot && usrNot.isOpen) {
            usrNot.close()
        }
    }

    useEffect(() => {
        if (appData.user) {
            
            M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), {
                alignment: 'right'
            });

            M.Sidenav.init(document.getElementById('userNot'), {
                edge: 'right',
                onOpenStart: () => {
                    closeUserNav()
                }
            });

            M.Sidenav.init(document.getElementById('userNav'), {
                edge: 'right',
                onCloseStart: () => {
                    setMenuProfile(false)
                },
                onOpenStart: () => {
                    closeUserNot();
                    setMenuProfile(true)
                }
            });

            M.Sidenav.init(document.getElementById('mNav'), {
                edge: 'left',
                onOpenStart: () => {
                    closeUserNav();
                    closeUserNot();
                }
            });

            notificacionesF();


            getMSaldo();

            bitacoraUsr(appData.user.IdUsuario)

           

            //si el usuario es primera vez, mostrar elmodal de TC

            if (!appData.user.AceptoAvisoPrivacidad || !appData.user.AceptoTerminosYCondiciones) {

                M.Modal.init(document.getElementById('modalTC'), {
                    dismissible: false,
                    opacity: 1
                });
                M.Modal.getInstance(document.getElementById('modalTC')).open();
            }

            if (appData.user.AceptoAvisoPrivacidad && appData.user.AceptoTerminosYCondiciones
                && appData.user.UsuarioPV) {

                setCambioPassDirecto(true);
                M.Modal.init(document.getElementById('modalPV'), {
                    dismissible: false,
                    opacity: 1
                });
                M.Modal.getInstance(document.getElementById('modalPV')).open();

            }



        }

    }, [appData.user]);

    const bitacoraUsr = async (idUsuario) => {
        let bitacora = await getBitacora({ idUsuario });


        dispatch(setBitacora(bitacora))
    }



    const notificacionesF = async () => {
        try {

            let notificaciones = await getNotificaciones(appData.user.IdUsuario);

            setNotificaciones(notificaciones)
        } catch (error) {

        }

    }

    const getMSaldo = async () => {
        try {

            let muestraSaldo = await getMostrarSaldo(appData.user.IdUsuario);


            dispatch(setMuestraSaldoF(muestraSaldo));
        } catch (error) {

        }
    }

    const openNotifications = () => {
        try {
            let usrNot = M.Sidenav.getInstance(document.getElementById('userNot'));

            if (usrNot && usrNot.isOpen) {
                usrNot.close();
            } else if (usrNot && !usrNot.isOpen) {
                usrNot.open();
            }
        } catch (error) {

        }
    }

    const buscarProducto = (producto, productos) => {

        let existe = productos.filter(p => p.TipoCliente === producto);

        return existe.length;
    }


    return (

        <React.Fragment>
            <GeolocationPermissionListener />
            {
                appData.user ?

                    <React.Fragment>


                        <ul id="mNav" className="sidenav">
                            <li className="center-align">

                                <img src={Logo} alt="Valmex Si" className="responsive-img mt-1" />

                            </li>
                            <li>
                                <li><Link to="/" className="labelNavy">Inicio</Link></li>
                                <li><Link to="/" className="labelNavy">Prestamos</Link></li>
                                <li><a href="#" onClick={openNotifications}>Notificaciones</a></li>
                                <li><Link to="/" className="labelNavy">Información de fondos</Link></li>
                                <li><Link to="/" className="labelNavy">Contacto</Link></li>
                                <li><Link to="/" className="labelNavy">Calcular tu Pensión</Link></li>
                                <li><Link to="/" className="labelNavy">Legales</Link></li>
                            </li>
                        </ul>

                        <ul id="userNot" className="sidenav">
                            <li className="mb-n-8">
                                <div className="row mb-0">
                                    <div className="col s12 ft-14 fw-400 txt_main-dark-blue">
                                        Notificaciones
                                    </div>
                                </div>

                            </li>
                            <li><div className="divider"></div></li>
                            {
                                notificaciones.length ?
                                    notificaciones.map((n, i) => {
                                        return (
                                            <li className="notification" key={i}>
                                                <div className="row">
                                                    <div className="col s12 left-align ft-15 fw-400 txt_main-dark-blue">
                                                        {n.title}
                                                    </div>
                                                    <div className="col s12 left-align bodyNot ft-15 fw-300">
                                                        {n.body}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })

                                    : <li className="notification">
                                        <div className="row">
                                            <div className="col s12 left-align ft-15 fw-400 txt_main-dark-blue">
                                                Sin Notificaciones
                                            </div>
                                            <div className="col s12 left-align bodyNot ft-15 fw-300">

                                            </div>
                                        </div>
                                    </li>
                            }
                        </ul>

                        <ul id="userNav" className="sidenav">
                            <li className="mb-16px">
                                <div className="row">
                                    <div className="col s12" style={{ lineHeight: "1", marginTop: "10px" }}>
                                        <p className="ft-16 labelNavy truncate">¡Hola! {appData.user.Nombre + " " + appData.user.ApPaterno}</p>
                                        <p className="ft-16 labelNavy">No. empleado: {appData.user.Clave}</p>
                                        <p className="ft-16 labelNavy">Último acceso: {formatFecha(appData.user.FechaUltAcceso)}</p>
                                    </div>
                                </div>
                            </li>
                            <li><Link to="/perfil" className="labelNavy sidenav-close"><i className="material-icons labelNavy">person</i>Mi Perfil</Link></li>
                            <li><Link to="/perfil/cuentas" className="labelNavy sidenav-close"><i className="material-icons labelNavy">credit_card</i>Cuentas Registradas</Link></li>
                            {/* <li><Link to="/perfil" className="labelNavy sidenav-close"><i className="material-icons labelNavy">swap_horiz</i>Beneficiarios</Link></li> */}
                            <li><Link to="/perfil/seguridad" className="labelNavy sidenav-close"><i className="material-icons labelNavy">security</i>Seguridad</Link></li>
                            <li><div className="divider"></div></li>
                            <li className="mt-10px"><Link to="/acercade/productos" className="labelNavy sidenav-close">Productos</Link></li>
                            <li><Link to="/acercade/contacto" className="labelNavy sidenav-close">Contacto</Link></li>
                            <li><Link to="/calcular_retiro_s" className="labelNavy sidenav-close">Calcula tu Pensión</Link></li>
                            <li><Link to="/educacion_financiera_s" className="labelNavy sidenav-close">Educación Financiera</Link></li>
                            <li><Link to="/acercade/terminosycondicions" className="labelNavy sidenav-close">Términos y Condiciones</Link></li>
                            <li><Link to="/acercade/avisodeprivacidad" className="labelNavy sidenav-close">Aviso de Privacidad</Link></li>
                            <li><div className="divider"></div></li>
                            <li><a href="#!" className="labelNavy sidenav-close"
                                onClick={cerrarSesion}
                            ><i className="material-icons labelNavy" >exit_to_app</i>Cerrar sesión</a></li>
                        </ul>


                        <div className="row navHome">
                            <div className="col s2 m2 l2 xl2">
                                <Link to="/" className="brand-logo">
                                    <img src={Logo_H} alt="Valmex Si" className="responsive-img logoNav" />
                                    <img src={Logo} alt="Valmex Si" className="responsive-img logoNav_m " />
                                </Link>
                            </div>
                            <div className="col s5 m7 l6 center-align wrapperNavButtons" >
                                <div className="row">
                                    <div className="col s12 m3 noPadding">
                                        <Link to="/" className={`btn-flat btnMenu optMenu waves-effect ${location.pathname === '/' ? "btnBalances" : ""}`}>
                                            Mis Balances
                                        </Link>
                                    </div>
                                    {
                                        appData && appData.user ?
                                            <React.Fragment>
                                                {
                                                    buscarProducto('Fondo de Ahorro', appData.user.Productos) ?
                                                        <div className="col s12 m3 noPadding">
                                                            <Link to="/fondodeahorro" className={`btn-flat btnMenu optMenu waves-effect ${location.pathname.includes('fondodeahorro') ? "btnFondoAhorro" : ""}`}>
                                                                Fondo de Ahorro
                                                            </Link>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    buscarProducto('Caja de Ahorro', appData.user.Productos) ?
                                                        <div className="col s12 m3 noPadding">
                                                            <Link to="/cajadeahorro" className={`btn-flat btnMenu optMenu waves-effect ${location.pathname.includes('cajadeahorro') ? "btnCajaAhorro" : ""}`}>
                                                                Caja de Ahorro
                                                            </Link>
                                                        </div>
                                                        : null
                                                }
                                                {
                                                    buscarProducto('Fondo de Pensiones', appData.user.Productos) ?
                                                        <div className="col s12 m3 noPadding">
                                                            <Link to="/plandepensiones" className={`btn-flat btnMenu optMenu waves-effect ${location.pathname.includes('plandepensiones') ? "btnPlanPension" : ""}`}>
                                                                Plan de Pensiones
                                                            </Link>
                                                        </div>
                                                        : null
                                                }

                                            </React.Fragment>

                                            : null

                                    }



                                </div>

                            </div>
                            <div className="col s2 l1 right-align  ">
                                <button type="button"
                                    className='btn btnNotification white waves-effect'
                                    onClick={() => {

                                        logEvent(analytics, "notificaciones", {
                                            screen_name: "Hamburguesa",
                                        });

                                        openNotifications()

                                    }}>
                                    <i className="material-icons black-text notranslate">notifications</i>
                                </button>
                            </div>
                            <div className="col s1 m1 l3 xl2 cursorPointer"
                                onClick={() => {
                                    try {
                                        let usrNav = M.Sidenav.getInstance(document.getElementById('userNav'));

                                        if (usrNav && usrNav.isOpen) {
                                            usrNav.close();
                                        } else if (usrNav && !usrNav.isOpen) {
                                            usrNav.open();
                                        }
                                    } catch (error) {

                                    }

                                }}
                            >
                                <Avatar name={appData.user.Nombre} round={true} size="40" className="avatarText" color="#8ADAE5" />
                                <span className="hide-on-med-and-down">{" " + appData.user.Nombre + " "}</span> <i className="material-icons chProfile hide-on-med-and-down">
                                    {menuProfile ? 'expand_less' : 'expand_more'}
                                </i>
                            </div>
                        
                        </div>
                        <div className="wrapperOutlet" onClick={() => {
                            closeUserNav();
                            closeUserNot();
                        }}>
                           
                            <Outlet />
                        </div>
                        {
                            appData.user ?
                                <SesionInactiva tiempoInactividadMinutos={appData.user.timeInactividad.timerInactividad}
                                    tiempoSesionMinutos={appData.user.timeInactividad.timerSesion} />
                                : null

                        }

                    </React.Fragment>

                    : <Outlet />
            }

            <ModalTC />
            <ModalPV cambioPassDirecto={cambioPassDirecto} />
        </React.Fragment>
    )




}

