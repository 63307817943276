import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { nWithCommas } from "../utils";
import { plugins } from "chart.js";



const COLORS_CAJA_AHORRO = [
    '#490E67',
    '#8018B4',
    '#9852CA',
    '#AF69D3',
    '#BA5DE9',
    '#CB8CEC',
    '#E2CFEF',
    '#EED5FB',
    '#F3E8F9',
    '#FBF5FE',
]

const COLORS_FONDO_PENSION = [
    '#293259',
    '#475498',
    '#6573B7',
    '#8F9ACA',
    '#98A4DE',
    '#BAC3EE',
    '#C0C8EC',
    '#D3D7EC',
    '#EBEEFB',
    '#F6F8FF',
]


function PortafolioInversion() {

    const appData = useSelector((state) => state.appData)
    const navigate = useNavigate();
    const params = useParams();

    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: '/',
        fechaUltActividad: '',
        saldos: false
    });

    const [graphRef, setGraphRef] = useState(false);

    const [COLORS, setColors] = useState([]);

    useEffect(()=>{
        if(dataPage.titulo.trim() !== ""){
            if(dataPage.titulo.trim().toUpperCase() === 'FONDO DE PENSIONES' ){
                setColors(COLORS_FONDO_PENSION);
            }

            if(dataPage.titulo.trim().toUpperCase() === 'CAJA DE AHORRO'){
                setColors(COLORS_CAJA_AHORRO);
            }
        }
    },[dataPage])


    function getDataPage({ titulo, to_return }) {
        let fechaUltActividad = '';
        if (appData.saldosFecha) {

            for (let saldo of appData.saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === titulo.toUpperCase()) {
                        fechaUltActividad = saldo.FechaUltActividad
                    }
                }
            }
        }

        if (appData.saldosTrimestrales) {
            if (appData.saldosTrimestrales && appData.saldosTrimestrales.saldosAgrupadosXPlan && appData.saldosTrimestrales.saldosAgrupadosXPlan.length) {
                for (let saldos of appData.saldosTrimestrales.saldosAgrupadosXPlan) {
                    if (saldos.Producto && saldos.Producto.TipoCliente.toUpperCase() === titulo.toUpperCase()) {


                        let total_rendimientos = saldos.saldos.reduce((total, elemento) => total + elemento.rendimiento, 0);
                        let total_saldo = saldos.saldos.reduce((total, elemento) => total + elemento.saldo, 0);
                
                        
                        setDataPage({
                            ...dataPage,
                            titulo,
                            to_return,
                            fechaUltActividad: fechaUltActividad,
                            saldos: saldos.saldos,
                            dataGraph: makeDataGraph(saldos.saldos, titulo),
                            total_rendimientos: total_rendimientos,
                            total_saldo: total_saldo
                        })
                    }
                }
            }
        }
    }

    function makeDataGraph(saldos,titulo) {
        let colores = [];
        if(titulo.trim().toUpperCase() === 'FONDO DE PENSIONES' ){
            colores = COLORS_FONDO_PENSION ;
        }

        if(titulo.trim().toUpperCase() === 'CAJA DE AHORRO'){
            colores = COLORS_CAJA_AHORRO ;
        }

        let objData = {
            labels: [],
            datasets: [
                {
                    label: [],
                    data: [],
                    backgroundColor: colores,
                    borderColor: colores,
                    borderWidth: 1,
                },
            ],
        }

        for (let saldo of saldos.filter((s) => parseFloat(s.saldo) > 0.01)) {
            objData.datasets[0].data.push(parseFloat(saldo.saldo))
        }

        return objData
    }

    function cambioEfectivo(plan) {
        if (plan.startsWith('EFE')) {
            return plan.replace(plan, 'Efectivo')
        }

        return plan;
    }

    useEffect(() => {

        let grafica_new = false;

        if(graphRef){
            graphRef.destroy();
        }

        if (dataPage.dataGraph && COLORS.length) {

            const ctx_new = document.getElementById('grafica_nueva').getContext('2d');

            const customPlugin = {
                id: 'customTextPlugin',
                beforeDraw: (chart) => {
                    const ctx = chart.ctx;
                    const { width, height } = chart;
                    const { datasets } = chart.data;
                    
                    ctx.save();
            
                    // Configuración del texto
                    const fontSize = ((height / 3) / 114).toFixed(2);
                    ctx.font = `${fontSize}em sans-serif`;
                    ctx.textBaseline = 'middle';
            
                    // Partes del texto
                    const firstLine = `$ ${nWithCommas(Math.round(dataPage.total_saldo).toFixed(2))}`;
                    const secondLine = 'Saldo Total';
            
                    // Calcular posición para centrar cada línea
                    const firstLineX = Math.round((width - ctx.measureText(firstLine).width) / 2);
                    const secondLineX = Math.round((width - ctx.measureText(secondLine).width) / 2);
            
                    const textY = height / 2;
            
                    // Ajuste para el espaciado entre líneas
                    const lineSpacing = 20; // Espaciado entre las dos líneas
            
                    // Dibujar ambas líneas
                    ctx.fillText(firstLine, firstLineX, textY - lineSpacing);
                    ctx.fillText(secondLine, secondLineX, textY + lineSpacing);
            
                    ctx.restore();
                }
            };

            grafica_new = new window.Chart(ctx_new, {
                type: 'doughnut',
                data: dataPage.dataGraph,
                options: {
                    rotation: dataPage.saldos.length === 1 ? 1.5 : 0,
                    cutout: '60%', // Nota: Chart.js 3 usa `cutout` en lugar de `cutoutPercentage`
                    layout: {
                        padding: {
                            top: dataPage.saldos.length === 1 ? 0 : 10
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                        tooltip: {
                            enabled: false // Desactivar tooltips
                        }
                    }
                },
                plugins: [customPlugin] // Registrar el plugin aquí
            });

            setGraphRef(graphRef);

        }

        return () => {

            if (grafica_new) {
                grafica_new.destroy();
            }

        }
    }, [COLORS])

    useEffect(() => {
        if (params.tipo && params.tipo === 'fondodeahorro') {
            getDataPage({ titulo: 'Fondo de Ahorro', to_return: '/fondodeahorro' })
        } else if (params.tipo && params.tipo === 'cajadeahorro') {
            getDataPage({ titulo: 'Caja de Ahorro', to_return: '/cajadeahorro' })
        } else if (params.tipo && params.tipo === 'plandepensiones') {
            getDataPage({ titulo: 'Fondo de Pensiones', to_return: '/plandepensiones' })
        } else {
            navigate('/')
        }
    }, [params])

    return (
        <div className="container" style={{ minHeight: '100vh' }}>
            <div className="row mt-2">
                <div className="col s12 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> {dataPage.titulo}</p>
                    <h5 className="txtBold">Portafolio de Inversión</h5>
                </div>

                <div className="col s12" style={{
                    border: 'solid 1px #D8EDF8',
                    borderRadius: '20px'
                }}>
                    <div className="row">
                        <div className="col s6  chartContainer" >

                            {
                                dataPage.dataGraph && COLORS.length ?

                                    <canvas id="grafica_nueva" height={250} style={{marginTop:'5rem'}}></canvas>


                                    : null
                            }

                        </div>
                        <div className="col s6" style={{ paddingRight: '2rem' }}>
                            {/* <div className="col s12 m12 l6 xl6"> */}
                            <div className="col s12 right-align mt-3" style={{ paddingRight: '0px' }}>
                                <span>Información al {dataPage.fechaUltActividad.split(' ').length > 1 ? dataPage.fechaUltActividad.split(' ')[0] : dataPage.fechaUltActividad}</span>
                            </div>
                            <div className="row mb-0">
                                <div className="col s6 left-align txtBold mt-2">
                                    Fondos:
                                </div>
                                <div className="col s6 right-align txtBold mt-2">
                                    Saldo
                                </div>
                                <div className="col s12 mt-1">
                                    <div className="divider" />
                                </div>
                            </div>
                            <div className="col s12 mt-1">

                            </div>
                            {
                                dataPage.saldos && dataPage.saldos.length ?
                                    dataPage.saldos.filter((f) => parseFloat(f.saldo) > 0.01).map((v, i) => {
                                        return (
                                            <div className="row" key={i}>
                                                <div className="col s6 left-align">
                                                    <span className="colorFondo" style={{ backgroundColor: COLORS[i], color: COLORS[i] }}>aa</span> {cambioEfectivo(v.plan)}
                                                </div>
                                                <div className="col s6 right-align">
                                                    $ {nWithCommas(Math.round(v.saldo).toFixed(2))}
                                                </div>
                                                {
                                                    i < dataPage.saldos.length - 1 ?
                                                        <div className="col s12">
                                                            <div className="divider" />
                                                        </div> : null
                                                }

                                            </div>
                                        )
                                    })

                                    : null
                            }

                            {/* {
                                dataPage.total_rendimientos ?
                                    <div className="row totalPI">
                                        <div className="col s6 left-align txtBold">
                                            Rendimiento:
                                        </div>
                                        <div className="col s6 right-align ft-16 txtBold">
                                            $ {nWithCommas(parseFloat(dataPage.total_rendimientos).toFixed(2))}
                                        </div>

                                    </div>
                                    : null
                            } */}
                            {
                                dataPage.total_saldo ?
                                    <div className="row totalPI">
                                        <div className="col s6 left-align txtBold">
                                            Saldo Total :
                                        </div>
                                        <div className="col s6 right-align ft-16 txtBold">
                                            $ {nWithCommas(Math.round(dataPage.total_saldo).toFixed(2))}
                                        </div>

                                    </div>
                                    : null
                            }

                            <div className="row mt-3">
                                {
                                    dataPage.saldos && dataPage.saldos.map((s, i) => {

                                        const total_saldo = dataPage.saldos.reduce((total, elemento) => total + elemento.saldo, 0);
                                        const porcentaje = (s.saldo * 100) / total_saldo;


                                        return (
                                            <div className="col s4">
                                                <div className="row">
                                                    <div className="col s12 center-align" style={{fontWeight:'600'}}>
                                                        <div className="colorFondoPercent" style={{ backgroundColor: COLORS[i], color: COLORS[i], marginRight: '0.5rem', position: 'absolute' }}></div>
                                                        {Math.round(parseFloat(porcentaje))}%
                                                    </div>
                                                  
                                                    <div className="col s9 offset-s3" style={{fontSize:'13px', paddingLeft:'1rem'}}>
                                                        { cambioEfectivo(s.plan)}
                                                    </div>

                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>



                    </div>
                </div>


            </div>

        </div>
    )

}


export default PortafolioInversion;