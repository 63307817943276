
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import { formatearTimestamp } from '../utils';
import MapBitacora from "../components/mapBitacora";
import {useAnalytics} from '../AnalyticsContext'
import { logEvent } from "firebase/analytics";

function BitacoraAcceso() {

    const analytics = useAnalytics();

    useEffect(()=>{
        logEvent(analytics, "bitácora_acceso", {
            screen_name: "Perfil",
        });
    },[])

    const { bitacora } = useSelector((state) => state.appData)

    const [showMap, setShowMap] = useState('');

    const nameSeccion = (name) => {

        const nameUpper = name.toUpperCase();

        if (nameUpper.includes('WEB')) {
            return "Accesos Web";
        } else if (nameUpper.includes('ANDROID')) {
            return "Accesos Android";
        } else if (nameUpper.includes('IOS')) {
            return "Acessos IOS";
        } else {
            return "Otros Dispositivos";
        }
    }

    const nameNavegador = (name) => {

        const nameUpper = name.toUpperCase();

        if (nameUpper.includes('WEB')) {
            return "Web";
        } else if (nameUpper.includes('ANDROID')) {
            return "Android";
        } else if (nameUpper.includes('IOS')) {
            return "IOS";
        } else {
            return "Otros Dispositivos";
        }
    }

    const formatFecha = (fecha) =>{
        try {
            if(typeof fecha === 'string'){
                return fecha;
            }else{
                return formatearTimestamp(fecha)
            }
        } catch (error) {
            return ''
        }
       
    }

    const showBitacora = () => {
        const keys = Object.keys(bitacora)


        if (keys.length > 0) {

            return (
                <React.Fragment>
                    {
                        keys.map((k, ind) => {
                            return (
                                <div className={`row ${ind !== 0 ? 'mt-1' : ''} white br-8 border-gray`} key={ind}>
                                    <div className="col s12 left-align boldText ft-14 mt-1">
                                        {nameSeccion(k)}
                                    </div>
                                    {
                                        bitacora[k].length > 0 &&
                                        bitacora[k].map((v, i) => {
                                            
                                            const idMap = `${i}_${Math.floor(Math.random() * 4000)}`;
                                            const mapIdentifier = `${k}_${i}`;
                                            return (
                                                <div className='col s12 mt-1' key={i}>
                                                    <div className='row fw-400 mb-0 cursorPointer'
                                                    onClick={()=>{
                                                        setShowMap(`${k}_${i}`);
                                                    }}
                                                    >
                                                        <div className="col s6 left-align txtBold ft-15">
                                                            Inicio de Sesión
                                                        </div>
                                                        <div className="col s6 right-align txtBold ft-15">
                                                            {formatFecha(v.fecha)}
                                                        </div>
                                                        <div className="col s6 left-align ft-12">
                                                            {v.navegador || nameNavegador(k)} - {v.ip}
                                                        </div>
                                                        <div className="col s6 right-align ft-12">
                                                            <i className="material-icons" style={{ color: '#8ADAE5', fontSize: '12px' }}>location_on</i>{v.lugar}
                                                        </div>
                                                    </div>
                                                    {
                                                        v.latitude && v.longitud && mapIdentifier === showMap ?
                                                            <div className="col s12 mt-1" style={{ padding: '0px' }} >
                                                                <MapBitacora lat={v.latitude} long={v.longitud} idMap={idMap} />
                                                            </div>
                                                            : null
                                                    }
                                                    <div className="col s12 mt-1" style={{ padding: '0px' }}>
                                                    <div className="divider" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </React.Fragment>

            )

        }
        return null;
    }

    return (
        <div className="row ml-10">
            <div className="col s12 left-align">
                <Link to="/perfil/seguridad" className="ft-13 txt_main-dark-blue fw-400">Regresar <i className="material-icons left ft-20">arrow_back</i></Link>
            </div>
            <div className="col s12 left-align ft-18 mt-1">
                Bitácora de Accesos
            </div>
            <div className='col s12 mt-1'>
                <div className='row'>
                    <div className="center-align p-10 wPP">
                        {showBitacora()}
                    </div>

                </div>
            </div>
        </div>
    )

}

export default BitacoraAcceso;