import CryptoJS from 'crypto-js';
import { KEY, IV } from '../properties';
import moment from 'moment';
import 'moment/locale/es';

export const TXT_NO_DISPONIBLE = "Información no disponible.";
export const GEO_SERVICE = "https://api.bigdatacloud.net/data/reverse-geocode-client";
export const GET_IP = "https://api.ipify.org/?format=json";
export const URL_CLOUD_VALMEX = "https://us-central1-valmex-cb-qa.cloudfunctions.net";


export const TEXT_FIN_SESION_INACTIVIDAD = 'Tu sesión  ha expirado por inactividad , te invitamos a ingresar nuevamente.'
export const TEXT_FIN_SESION_JWT = 'Has superado el tiempo máximo de sesión permitida, te invitamos a ingresar nuevamente.'


const nombresMeses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
];

const nombresMesesAbreviado = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic'
];

export const encryptAES = (data) => {
    try {

        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(KEY), {
            iv: CryptoJS.enc.Utf8.parse(IV),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();

        return encrypted;
    } catch (error) {
        // console.log(error)
    }

}

export const formatFecha = (fechaOriginal) => {
    const fechaObjeto = new Date(
        parseInt(fechaOriginal.substr(0, 4)),   // Año
        parseInt(fechaOriginal.substr(4, 2)) - 1, // Mes (restamos 1 ya que los meses en Date van de 0 a 11)
        parseInt(fechaOriginal.substr(6, 2)),    // Día
        parseInt(fechaOriginal.substr(9, 2)),    // Horas
        parseInt(fechaOriginal.substr(12, 2)),   // Minutos
        parseInt(fechaOriginal.substr(15, 2))    // Segundos
    );

    // Función para agregar un cero a la izquierda si el número es menor que 10
    function agregarCero(num) {
        return num < 10 ? `0${num}` : num;
    }

    // Formatear la fecha según el nuevo formato
    const fechaFormateada = `${agregarCero(fechaObjeto.getDate())}/${agregarCero(fechaObjeto.getMonth() + 1)}/${fechaObjeto.getFullYear().toString().substr(-2)} - ${agregarCero(fechaObjeto.getHours())}:${agregarCero(fechaObjeto.getMinutes())} hr`;

    return fechaFormateada
}

export const fechaDate = (fecha) => {

    let fechaStr = fecha;

    // Extraer año, mes y día de la cadena
    const año = parseInt(fechaStr.substring(0, 4), 10);
    const mes = parseInt(fechaStr.substring(4, 6), 10) - 1; // Los meses en JavaScript van de 0 a 11, así que restamos 1.
    const día = parseInt(fechaStr.substring(6, 8), 10);

    // Crear un objeto Date
    var fechaReturn = new Date(año, mes, día);

    return fechaReturn;
}

export const nWithCommas = (x) => {
    //return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (isNaN(x)) {
        return '0.00';
    }
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export const cutLetters = (x) => {

    if (x.trim().length > 100) {
        return x.trim().substr(0, 97) + '...';
    }

    return x.trim();
}

export function onlyDigits(value) {
    var re = /^[0-9]*$/;
    return re.test(value);
}

export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email);
}

export const priceDiscount = ({ price, desc }) => {

    let discount = (parseFloat(price) * desc / 100);

    return '$' + nWithCommas(parseFloat(price - discount).toFixed(2));

}


export const REGEXS_NEW_PASS = [
    {
        text: "Debe ser de al menos de 8 caracteres.",
        validator: (value) => {
            if (value.length >= 8) {
                return true;
            }

            return false;
        },
        isCorrect: false
    },
    {
        text: "Debe contener al menos una mayúscula.",
        validator: (value) => {
            let re = /[A-Z]/;
            return re.test(value);
        },
        isCorrect: false
    },
    {
        text: "No debe contener la palabra VALMEX.",
        validator: (value) => {
            let re = /^(?!.*VALMEX).*$/;
            return re.test(value.toUpperCase())
        },
        isCorrect: false
    },
    {
        text: "No debe contener más de dos caracteres consecutivos iguales (p.e. 222, eee).",
        validator: (value) => {
            let re = /^(?!.*(\S)\1{2,})[\s\S]*$/ ///^(?!.*(.)\1\1)[A-Za-z0-9 ]*$/;
            return re.test(value);
        },
        isCorrect: false
    },
    {
        text: "No debe contener más de dos caracteres consecutivos (p.e. 123, abc)",
        validator: (value) => {
            let re = /^(?!(.)\1{2})(?!.*(012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|cba))[A-Za-z0-9 \w!@#$%^&*()-+=\[\]{}|;:'",.<>/?`~]*$/;

            return re.test(value.toLowerCase())
        },
        isCorrect: false
    },
    {
        text: "No debe contener espacios en blanco.",
        validator: (value) => {
            let re = /\s/;
            return !re.test(value);
        },
        isCorrect: false
    }
]

export const toTitleCase = (str) => {
    // Dividir la cadena en palabras individuales
    if (str) {
        const words = str.toLowerCase().split(' ');

        // Convertir la primera letra de cada palabra a mayúscula
        const titleCaseWords = words.map(word => {
            if (word.toUpperCase() === 'DE') {
                return 'de'
            }
            return word.charAt(0).toUpperCase() + word.slice(1);
        });

        // Unir las palabras en una sola cadena
        return titleCaseWords.join(' ');
    }
    return ''
}

export async function getCoordenadas() {

    if (navigator.geolocation) {
        return await new Promise(async (resolve) => {
            navigator.geolocation.getCurrentPosition(async (showPosition) => {
                let { latitude, longitude } = showPosition.coords;
                try {
                    let result = await fetch(`${GEO_SERVICE}?latitude=${latitude}&longitude=${longitude}`).then(response => response.json());

                    if (result) {
                        console.info("Obtuvo coordenadas");
                        let estado = result.principalSubdivision ? result.principalSubdivision : "";
                        let direccion = await getDireccion(latitude, longitude);
                        result.estado = estado;
                        result.direccion = direccion;
                        result.navegador = getBrowserType();

                        resolve(result)
                    } else {
                        resolve(false)
                    }
                } catch (e) {
                    resolve(false)
                }
            }, (error) => {
                resolve(false);
            });
        })
    } else {
        return false;
    }
}

function getBrowserType() {
    const test = regexp => {
        return regexp.test(navigator.userAgent);
    };

    if (test(/opr\//i) || !!window.opr) {
        return 'Opera';
    } else if (test(/edg/i)) {
        return 'Microsoft Edge';
    } else if (test(/chrome|chromium|crios/i)) {
        return 'Google Chrome';
    } else if (test(/firefox|fxios/i)) {
        return 'Mozilla Firefox';
    } else if (test(/safari/i)) {
        return 'Apple Safari';
    } else if (test(/trident/i)) {
        return 'Microsoft Internet Explorer';
    } else if (test(/ucbrowser/i)) {
        return 'UC Browser';
    } else if (test(/samsungbrowser/i)) {
        return 'Samsung Browser';
    } else {
        return 'Unknown browser';
    }
}

export async function getDireccion(lat, long) {

    try {
        let url = `${URL_CLOUD_VALMEX}/apigoogle/obtenerDireccion`;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ lat, long })
        };

        let res = await fetch(url, requestOptions)
            .then(response => response.json())

        if (res && res.length > 0) {
            let address = res[0].formatted_address;

            return address;
        } else {
            return "";
        }
    } catch (error) {

        return "";
    }
}

export async function getIp() {
    try {
        let result = await fetch(`${GET_IP}`).then(response => response.json())
        return result;
    } catch (e) {
        return false
    }
}

export function processPrestamos(prestamos) {
    let obj = {
        'APLICADO': [],
        'LIQUIDADO': [],
        'PENDIENTE': [],
        'HISTORIAL': []
    }

    for (const prestamo of prestamos) {
        if (obj[prestamo.Estatus.toUpperCase()]) {
            obj[prestamo.Estatus.toUpperCase()].push(prestamo)
        }

        obj['HISTORIAL'].push(prestamo)
    }
    return obj;

}

export function processFecha(fechaTexto) {
    // Extraer año, mes y día de la cadena

    if (fechaTexto.length === 8) {
        var año = fechaTexto.substring(0, 4);
        var mes = fechaTexto.substring(4, 6);
        var día = fechaTexto.substring(6, 8);

        // Crear una nueva cadena en el formato DD/MM/YY
        var fechaFormateada = día + "/" + mes + "/" + año.slice(-2);

        return fechaFormateada;
    }

    return ''

}

export function processFechaGuion(fechaTexto) {
    // Extraer año, mes y día de la cadena

    if (fechaTexto.length === 8) {
        var año = fechaTexto.substring(0, 4);
        var mes = fechaTexto.substring(4, 6);
        var día = fechaTexto.substring(6, 8);

        // Crear una nueva cadena en el formato DD/MM/YY
        var fechaFormateada = día + "-" + mes + "-" + año;

        return fechaFormateada;
    }

    return ''

}

export function getNombreMes(mes) {
    return nombresMeses[mes]
}

export function getNombreMesAbreviado(mes) {
    return nombresMesesAbreviado[mes]
}

export function currentYear() {
    const fechaActual = new Date();
    const ultimoDosDigitos = fechaActual.getFullYear() % 100;
    return ultimoDosDigitos;
}

export function processFechaNM(fechaTexto) {
    // Extraer año, mes y día de la cadena

    if (fechaTexto.length === 8) {
        var año = fechaTexto.substring(0, 4);
        var mes = fechaTexto.substring(4, 6);
        var día = fechaTexto.substring(6, 8);

        const mesNombre = nombresMeses[parseInt(mes) - 1];

        // Crear una nueva cadena en el formato DD/MM/YY
        var fechaFormateada = día + "/" + mesNombre + "/" + año.slice(-2);

        return fechaFormateada;
    }

    return ''

}

export function processFechaNMGrafica(fechaTexto) {
    // Extraer año, mes y día de la cadena

    if (fechaTexto.length === 8) {
        var año = fechaTexto.substring(0, 4);
        var mes = fechaTexto.substring(4, 6);
        var día = fechaTexto.substring(6, 8);

        const mesNombre = nombresMesesAbreviado[parseInt(mes) - 1];

        // Crear una nueva cadena en el formato DD/MM/YY
        var fechaFormateada = mesNombre + ' ´' + año.slice(-2);

        return fechaFormateada;
    }

    return ''

}

export function processFechaActual() {
    // Obtener la fecha actual en milisegundos
    const fechaActualEnMilisegundos = Date.now();

    // Crear un objeto Date con la fecha actual
    const fecha = new Date(fechaActualEnMilisegundos);

    // Extraer el día, mes y año de la fecha
    const dia = fecha.getDate();
    const mesNumero = fecha.getMonth(); // Los meses en JavaScript van de 0 a 11.
    const año = fecha.getFullYear();

    // Obtener el nombre del mes a partir del número
    const mesNombre = nombresMeses[mesNumero];

    // Formatear los componentes en una cadena DD de MesNombre de YYYY
    return `${dia.toString().padStart(2, '0')}/${mesNombre}/${año}`;

}


export function getClassBtn(producto, btnS) {
    let classBtn = '';

    if (producto.toUpperCase() === 'FONDO DE AHORRO' && btnS.toUpperCase() === 'FONDO DE AHORRO') {
        classBtn = `btnFondoAhorro`
    } else if (producto.toUpperCase() === 'CAJA DE AHORRO' && btnS.toUpperCase() === 'CAJA DE AHORRO') {
        classBtn = `btnCajaAhorro`
    } else if (producto.toUpperCase() === 'PLAN DE PENSIONES' && btnS.toUpperCase() === 'PLAN DE PENSIONES') {
        classBtn = `btnPlanPension`
    } else if (producto.toUpperCase() === 'FP' && btnS.toUpperCase() === 'FONDO DE PENSIÓN') {
        classBtn = `btnPlanPension`
    } else if (producto.toUpperCase() === 'FA' && btnS.toUpperCase() === 'FONDO DE AHORRO') {
        classBtn = `btnFondoAhorro`
    } else if (producto.toUpperCase() === 'CA' && btnS.toUpperCase() === 'CAJA DE AHORRO') {
        classBtn = `btnCajaAhorro`
    }

    else {
        classBtn = 'btn-flat hoverGray'
    }

    return classBtn
}

export const convertirCamelCase = (texto) => {
    // Divide el texto en palabras usando un espacio en blanco como delimitador
    // Divide el texto en palabras usando espacios en blanco como delimitador
    const palabras = texto.toLowerCase().split(' ');

    // Capitaliza la primera letra de cada palabra
    for (let i = 0; i < palabras.length; i++) {
        palabras[i] = palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1);
    }

    // Une las palabras en un solo string con espacios
    const resultado = palabras.join(' ');

    return resultado;
}

export const eliminarAcentos = (texto) => {
    return texto
        .normalize("NFD") // Normaliza la cadena en la forma "NFD" (Descomposición Canónica)
        .replace(/[\u0300-\u036f]/g, ""); // Elimina los caracteres diacríticos
}


export const formatearTimestamp = (timestamp) => {
    // Crea un objeto Date a partir del timestamp
    var fecha = new Date(timestamp);

    // Obtiene los componentes de fecha y hora
    var dia = fecha.getDate().toString().padStart(2, '0');
    var mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Los meses comienzan en 0
    var año = fecha.getFullYear();
    var horas = fecha.getHours().toString().padStart(2, '0');
    var minutos = fecha.getMinutes().toString().padStart(2, '0');

    // Formatea la fecha y la hora en el formato deseado
    var formato = dia + '/' + mes + '/' + año + ' ' + horas + ':' + minutos;

    return formato;
}

export const compararPorFecha = (a, b) => {
    return b.fecha - a.fecha;
}

export const stringDateToTimestamp = (fechaStr, init) => {
    var anio = parseInt(fechaStr.substr(0, 4));
    var mes = parseInt(fechaStr.substr(4, 2)) - 1; // Resta 1 al mes, ya que los meses en JavaScript comienzan desde 0
    var dia = parseInt(fechaStr.substr(6, 2));

    let valorFebrero = 28;

    if (esBisiesto(anio)) {
        valorFebrero = 29;
    }

    var finMeses = [31, valorFebrero, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Crea un objeto Date

    if (init) {
        dia = 1;
    } else {
        dia = finMeses[mes];
    }


    var fecha = new Date(anio, mes, dia);

    // Obtiene el timestamp
    return fecha.getTime();
}

function esBisiesto(ano) {
    // Un año es bisiesto si es divisible por 4
    // pero no es divisible por 100 a menos que también sea divisible por 400
    return (ano % 4 === 0 && ano % 100 !== 0) || (ano % 400 === 0);
}

export function obtenerFechaActualString() {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = (fechaActual.getMonth() + 1).toString().padStart(2, '0'); // Añade un 0 al mes si es necesario
    const day = fechaActual.getDate().toString().padStart(2, '0'); // Añade un 0 al día si es necesario

    return `${year}${month}${day}`;
}

export const negativos = (dato) => {

    if (dato.includes('$-')) {
        return dato.replace('$-', '-$')
    }

    return dato;
}

export const agrupaMovFecha = (movimientos) => {
    let obj = {};
    for (const mov of movimientos) {
        if (obj[mov.Fecha]) {
            obj[mov.Fecha].push(mov);
        } else {
            obj[mov.Fecha] = [mov]
        }
    }

    return obj;

}


export const processMovimientosUtils = (movimientos) => {

    if (movimientos.length) {

        for (const mov of movimientos) {

            let cuenta = mov.Cuenta;
            if (mov.hasOwnProperty('Cuenta') && mov.Cuenta.includes('- EFE')) {
                let splitCuenta = mov.Cuenta.split('- EFE');

                cuenta = splitCuenta[0] + ' - ' + ' Efectivo';
            }

            if (mov.hasOwnProperty('Concepto') && mov.hasOwnProperty('Cuenta')) {
                mov['descrip'] = `${mov.Concepto} - ${cuenta}`;
            }

            if (mov.hasOwnProperty('Concepto') && !mov.hasOwnProperty('Cuenta')) {
                mov['descrip'] = `${mov.Concepto}`;
            }

            mov['date'] = fechaDate(mov.Fecha);
            mov['timestamp'] = stringDateToTimestamp(mov.Fecha)

        }


        const p5 = movimientos.sort((a, b) => b.timestamp - a.timestamp).slice(0, 5);
        return agrupaMovFecha(p5);
    } else {
        return []
    }
}

export const cambiaEfectivo = (text) => {
    
    if (text.toUpperCase().includes('- EFE')) {
        debugger;
        let splitCuenta = text.toUpperCase().split('- EFE');

        text = splitCuenta[0] + ' - ' + ' Efectivo';
    }

    return text.toLowerCase();

}


export const processSaldos = (saldos, titulo) => {
    for (let saldo of saldos) {
        if (saldo.Producto.TipoCliente === titulo) {
            return saldo.saldos;
        }
    }
}


const ordenaPrecios = (fondo, data) => {
    let preciosMes = data.TTPRECIOSMES["TT-PRECIOSMES"];
    let filtrado = preciosMes.filter((v, i) => {
        if (v.Fondo.trim().toUpperCase() === fondo.trim().toUpperCase()) {
            return v;
        }
    });

    return filtrado;
}

function filtrarPorPrimerDiaDelMes(arregloDeObjetos) {
    return arregloDeObjetos.filter(objeto => {
        // Parsear la cadena de fecha usando Moment.js
        const fecha = moment(objeto.Fecha, 'YYYY-MM-DD');

        // Verificar si es el primer día del mes
        return fecha.date() === 1;
    });
}

function filtrarPorPrimerDiaDelUltimoAnio(arregloDeObjetos) {
    const ultimoAnio = moment().year() - 1;

    const anioActual = moment().year();

    const diaActual = moment();



    // Utilizar un objeto para realizar un seguimiento de los meses ya encontrados
    const mesesEncontrados = {};

    return arregloDeObjetos.filter(objeto => {
        const fecha = moment(objeto.Fecha, 'YYYY-MM-DD');

        // Verificar si pertenece al último año
        const esUltimoAnio = fecha.year() === ultimoAnio;

        const esAnioActual = fecha.year() === anioActual;



        if (esUltimoAnio || esAnioActual) {
            // Verificar si ya se ha encontrado un día para este mes
            const mesActual = fecha.month() + '-' + fecha.year();
            if (!mesesEncontrados[mesActual]) {
                // Marcar este mes como encontrado y devolver true
                mesesEncontrados[mesActual] = true;
                return true;
            }
        }

        if (fecha.isSame(diaActual, 'day')) {
            return true;
        }

        return false;
    });
}

export const makeInfoChart = (inf) => {

    let { preciosMes } = inf;
    let d = [];
    let cat = [];

    let preciosPrimerDia = filtrarPorPrimerDiaDelUltimoAnio(preciosMes);

    for (let pm of preciosPrimerDia) {
        d.push(pm.Precio);
        cat.push(moment(pm.Fecha).locale('es').format('DD-MMM-YY'));
    }

    if (d.length && cat.length) {


        return {
            series: [{
                name: "Precio",
                data: d
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: cat,
                    labels: {
                        show: true,
                        rotate: -45,
                        rotateAlways: true,
                    }
                }
            },
            info: inf

        };
    }
    return false;
}

export const ordenaInfo = (data) => {
    let info = data.TTINFOFONDOS["TT-INFOFONDOS"];

    let infoFondosOrden = {};
    //Ordena los fondos
    if (info && info.length) {
        for (let inf of info) {
            if (!infoFondosOrden[inf.Clasificacion.trim().toUpperCase()]) {
                let filtrado = info.filter((v, i) => {
                    if (v.Clasificacion.trim().toUpperCase() === inf.Clasificacion.trim().toUpperCase()) {
                        v.preciosMes = ordenaPrecios(v.Fondo, data);
                        return v;
                    }
                });
                infoFondosOrden[inf.Clasificacion.trim().toUpperCase()] = filtrado;
            }
        }

        let infoToShow = false;
        let keys = Object.keys(infoFondosOrden);
        if (keys.length) {
            infoToShow = infoFondosOrden[keys[0]][0];

        }


        let chartData = false;

        if (infoToShow) {
            chartData = makeInfoChart(infoToShow);
        }

        return { infoFondos: infoFondosOrden, info: infoToShow, chartData };
    }
}

export function getAntiguedad(data) {

    if (data.Productos && data.Productos.length) {



        const fechaCadena = data.Productos[0].FechaAltaEmpresa;

        if (fechaCadena === '01/01/2000 12:00:00 a. m.') {
            return 'Información no disponible'
        } else {
            // Convierte la cadena en un objeto moment
            const fecha = moment(fechaCadena, "DD/MM/YYYY hh:mm:ss a");

            // Obtiene la fecha actual
            const fechaActual = moment();

            // Calcula la diferencia en años
            const antiguedadEnAnios = fechaActual.diff(fecha, 'years');

            if (antiguedadEnAnios > 0) {
                if (antiguedadEnAnios > 1) {
                    return antiguedadEnAnios + ' Años'
                } else {
                    return antiguedadEnAnios + ' Año'
                }

            }
            return '';
        }



    }
    return false;
}

export function agregaAportacion(text) {

    // if (text.toUpperCase().includes('APORTACION') || text.toUpperCase().includes('APORTACIÓN')) {
    //     text = text.toUpperCase().replace('APORTACION', 'APORTACIÓN');
    // } else {
    //     text = 'Aportación ' + text.trim();
    // }

    return text;

}

export function capitalizeFirstLetter(text) {

    if (text) {

        // text = text.toUpperCase().replace('EMPLEADO', 'COLABORADOR').replace('PATRÓN', 'EMPRESA').replace('PATRON', 'EMPRESA')
        //     .replace('COMPAÑÍA', 'EMPRESA').replace('APORTACION', 'APORTACIÓN');

        text = text.toUpperCase().replace('APORTACION', 'APORTACIÓN');

    }

    if (text.includes("APORTACIÓN - APORTACIÓN")) {
        text = text.replace('APORTACIÓN - APORTACIÓN', 'APORTACIÓN - ')
    }



    return text.toLowerCase();

}

export function separarConceptos(data) {

    let dataFin = {};

    if (data && data.length) {
        for (let saldo of data) {

            const concepto = saldo.concepto.split(' - ');
            if (concepto.length > 1) {
                if (dataFin[concepto[0]]) {
                    dataFin[concepto[0]].push({ ...saldo, fondo: concepto[1] });
                } else {
                    dataFin[concepto[0]] = [{ ...saldo, fondo: concepto[1] }];
                }
            } else {
                dataFin[concepto] = [{ ...saldo, fondo: saldo.concepto }];
            }
        }
    }

    return dataFin;

}

export const cambioEfectivo = (plan) => {
    if (plan.startsWith('EFE')) {
        return plan.replace(plan, 'Efectivo')
    }

    return plan;
}


export const cambioEfectivoPdf = (plan) => {
    if (plan.toUpperCase().includes('- EFE')) {
        return 'Efectivo';
    }

    return plan;
}


export const obtenerPrimerDiaDelMes = (fechaStr) => {
    // Convertimos la cadena de fecha en un objeto Date
    const [dia, mes, anio] = fechaStr.split('-').map(Number);
    // Creamos una nueva fecha con el primer día del mes
    const primerDia = new Date(anio, mes - 1, 1);

    // Formateamos la fecha al formato "dd-MM-yyyy"
    const diaFormateado = primerDia.getDate().toString().padStart(2, '0');
    const mesFormateado = (primerDia.getMonth() + 1).toString().padStart(2, '0');
    const anioFormateado = primerDia.getFullYear();

    return `${diaFormateado}-${mesFormateado}-${anioFormateado}`;
}