import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import AnimateHeight from 'react-animate-height';
import { negativos } from "../utils";
import { useSelector } from 'react-redux';


function CardBalance({
    titulo,
    cantidad,
    banco,
    clave,
    saldo,
    derechoAdquirido,
    aportaciones,
    rendimiento,
    saldoTotal,
}) {

    const navigate = useNavigate();
    const appData = useSelector((state) => state.appData)
    const [subtitulo, setSubtitulo] = useState('')
    const [stilo, setStilo] = useState('')
    const [totalM, setTotalM] = useState('0.00');
    const [toGo, setToGo] = useState('')
    const [muestraDerecho, setMuestraDerecho] = useState(true);

    useEffect(() => {
        if (titulo.toUpperCase() == 'PLAN DE PENSIONES') {
            setSubtitulo('Total a Liquidar')
            setStilo('planPensionCard')
            setTotalM(cantidad)
            setToGo('plandepensiones')
        } else if (titulo.toUpperCase() == 'FONDO DE AHORRO') {
            setSubtitulo('Saldo Total')
            setStilo('fondosAhorroCard')
            setTotalM(cantidad)
            setToGo('fondodeahorro')
            setMuestraDerecho(false);
        } else if (titulo.toUpperCase() == 'CAJA DE AHORRO') {
            setSubtitulo('Saldo Total')
            setStilo('cajaAhorroCard')
            setTotalM(cantidad)
            setToGo('cajadeahorro')
        }
    }, [])
    




    const [height, setHeight] = useState(0);

    return (
        <div onMouseEnter={() => {
            setHeight('auto')
        }}
            onMouseLeave={() => {
                setHeight(0)
            }}
        >
            <div className={`cardBalance ${stilo}  row`}>
                <div className="col s10 left-align ft-18">
                    {titulo.toUpperCase()}
                </div>
                <div className="col s2 right-align">
                    <span className="badge badgeCard">Activo</span>
                </div>
                <div className="col s12 left-align mt-1 ft-12">
                    {subtitulo}
                </div>
                <div className="col s12 left-align mt-1 cantidad ft-28">
                    {
                        appData.muestraSaldo ? 
                        negativos(totalM)
                        :  '$****.**'

                        
                    }
                  
                </div>
                {
                    banco && banco.trim() !== "" ?
                        <div className="col s6 left-align">
                            Santander
                        </div>
                        : null
                }
                {
                    clave && clave.trim() !== "" ?
                        <div className="col s6 right-align">
                            Clabe: 23456789123
                        </div> : null
                }
            </div>
            <div className="wrapperListItems" duration={200} height={height}>

                <div className={`listItems ${stilo}  row ft-13`}>
                    <div className="col s12 center-align seeDetail cursorPointer mt-1"
                        onClick={() => {

                            navigate('/' + toGo)
                        }}
                    >
                        Ver Detalles
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CardBalance;