
import React, { useEffect, useState, useMemo } from "react"
import { useNavigate, useParams } from "react-router"
import { useSelector } from 'react-redux';
import { encryptAES, processPrestamos, nWithCommas, processFecha, TEXT_FIN_SESION_JWT } from "../utils";
import { BASE_URL } from "../properties";
import { useDispatch } from "react-redux";
import { toogleLoad, setSaldosPrestamos, setTokenSesion, setConfigPrestamo, showModalFinSesion } from "../redux/slices/appslice";
import M from 'materialize-css'
import { MaterialReactTable } from 'material-react-table';
import HalfDonut from "../components/halfdonut";
import { getConfigPrestamos } from '../ws';
import { useAnalytics } from "../AnalyticsContext";
import { logEvent } from "firebase/analytics";


function Prestamos() {

    const analytics = useAnalytics();
    const dispatch = useDispatch()
    const appData = useSelector((state) => state.appData)
    const navigate = useNavigate()
    const params = useParams();
    const [prestamoSelected, setPrestamoSelected] = useState(false)
    const [mostrarSolicitud, setMostrarSolicitud] = useState(false);

    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: '/',
        prestamos: []
    })

    useEffect(() => {

        dispatch(toogleLoad(true));
        M.Sidenav.init(document.getElementById('slide-detail'), {
            edge: 'right',
            onCloseStart: () => {
                setPrestamoSelected(false)
            }
        });

        return () => {
            setConfigPrestamo(false);
        }
    }, [])

    useEffect(() => {
        if (prestamoSelected) {
            //let mInstance = M.Modal.getInstance(document.getElementById('detailPrestamo'));
            //mInstance.open();
            M.Sidenav.getInstance(document.getElementById('slide-detail')).open();
        }
    }, [prestamoSelected])

    useEffect(() => {
        let title = '';
        if (params.tipo && params.tipo === 'fondodeahorro') {
            title = 'Fondo de Ahorro';
            getPrestamos({ titulo: title, to_return: '/fondodeahorro' });

        } else if (params.tipo && params.tipo === 'cajadeahorro') {
            title = 'Caja de Ahorro';
            getPrestamos({ titulo: title, to_return: '/cajadeahorro' });

        } else {
            navigate('/')
        }

        //Muestra el boton de solicitar prestamo.
        

    }, [params])

    const getPrestamos = async ({ titulo, to_return }) => {

        // if (!appData.saldosPrestamos) {
            try {
                let dataToEncrypt = {
                    "Usuario": appData.user.usuarioLogin,
                    "IdUsuario": `${appData.user.IdUsuario}`,
                    "Sesion": appData.user.Sesion,
                    "DeviceToken": "",
                    "SistemaOperativo": "ios",
                    "AppID": "SAF_APP",
                    "token": appData.tokenSesion,
                    "refresh_token": appData.token.refresh_token
                }

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': appData.idToken
                    },
                    body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
                };


                fetch(`${BASE_URL}/saldos/SaldosPrestamos`, requestOptions)
                    .then(response => response.json())
                    .then(async (data) => {

                        if (data.BanderaExito) {
                            if (data.Datos && data.Datos.length) {
                                dispatch(setSaldosPrestamos(data.Datos));
                                for (const dato of data.Datos) {
                                    if (dato.Producto[0].TipoCliente.toUpperCase() == titulo.toUpperCase()) {
                                        setDataPage({ ...dataPage, titulo, to_return: to_return, prestamos: processPrestamos(dato.ArregloPrestamos) })
                                        await getConfigPR(dato.Producto[0]);
                                    }
                                }
                            }

                            if (data.JWT && data.JWT.current_token) {

                                dispatch(setTokenSesion(data.JWT.current_token))
                            }
                        } else {
                            if (data.Mensaje === 'No existe sesion activa.' || data.Mensaje === 'Error al validar jwt. El token ingresado es inválido') {
                                dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
                            }
                        }
                        dispatch(toogleLoad(false));
                    });
            } catch (error) {

                dispatch(toogleLoad(false));
                M.toast({ html: "Error en servicio SP" })
            }
        // } else {
        //     dispatch(toogleLoad(false));
        //     for (const dato of appData.saldosPrestamos) {
       
        //         if (dato.Producto[0].TipoCliente.toUpperCase() == titulo.toUpperCase()) {
        //             setDataPage({ ...dataPage, titulo, to_return: to_return, prestamos: processPrestamos(dato.ArregloPrestamos) })
        //             debugger;
        //             await getConfigPR(dato.Producto[0]);
        //         }
        //     }
        // }
    }

    const getConfigPR = async (producto) => {
      
        let dataConfig = await getConfigPrestamos(appData, producto, () => {
            dispatch(showModalFinSesion({ texto: TEXT_FIN_SESION_JWT }))
        });

      
        if(dataConfig){
            let datosDataConfig  = dataConfig.Datos;

            datosDataConfig.productoConfig = producto;
            dispatch(setConfigPrestamo(datosDataConfig));
            if (appData && appData.user) {
                if (appData.user.Productos) {
                    for (let producto of appData.user.Productos) {
                        
                        if (producto.TipoCliente === 'Fondo de Ahorro' || producto.TipoCliente == 'Caja de Ahorro' && producto.Funciones && producto.Funciones.length) {
                            for (let funcion of producto.Funciones) {
                                if (funcion.Descripcion === "Solicitud de préstamos" && funcion.Activa) {
                                    setMostrarSolicitud(true);
                                }
                            }
                        }
                    }
                }
            }
           
            if (dataConfig.JWT) {
                dispatch(setTokenSesion(dataConfig.JWT.current_token))
            }
        }else{

            setMostrarSolicitud(false);
        }

      
    }

    function getPorcentaje(plazo, plazos) {
        return (plazo / plazos) * 100
    }

    const columns = useMemo(

        () => [
            {
                accessorKey: 'IdPrestamo', //access nested data with dot notation
                header: 'Préstamo',
                Cell: ({ cell }) => (
                    <span>Préstamo {cell.getValue()}</span>
                ),
                size: 150,
            },
            {
                accessorKey: 'Estatus', //access nested data with dot notation
                header: 'Estado',
                Cell: ({ cell }) => {

                    let clase = '';


                    if (cell.getValue() === 'Pendiente') {
                        clase = 'badgePrestamoActual';
                    }

                    if (cell.getValue() === 'Aplicado') {
                        clase = 'badgePrestamoActivo';
                    }


                    return <span className={`badge ${clase}`}>{cell.getValue()}</span>
                },
                size: 150,
            },
            {
                accessorKey: 'FechaPrestamo', //access nested data with dot notation
                header: 'Fecha de Solicitud',
                Cell: ({ cell }) => (
                    processFecha(cell.getValue())
                ),
                size: 150,
            },
            {
                accessorKey: 'Monto', //access nested data with dot notation
                header: 'Monto Solicitado',
                Cell: ({ cell }) => (
                    <span className="txtBold">${(nWithCommas(parseFloat(cell.getValue()).toFixed(2)))}</span>
                ),
                size: 150,
            },
        ], [],
    );


    return (
        <div className="container" style={{ minHeight: '90vh' }}>
            <div className="row mt-2">
                <div className="col s6 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> {dataPage.titulo}</p>
                    <h5 className="txtBold">Préstamos</h5>
                </div>
                <div className="col s6 right-align">
                    {
                        mostrarSolicitud ?
                            <button className="btn btnPrestamo mt-2"
                                onClick={(() => {

                                    let evt = '';

                                    if(params.tipo === 'cajadeahorro'){
                                        evt = 'acceso_solicitud_prestamo_ca'
                                    }

                                    if(params.tipo === 'fondodeahorro'){
                                        evt = 'acceso_solicitud_prestamo_fa'
                                    }


                                    logEvent(analytics, evt, {
                                        screen_name: "Solicitud de Prestamo",
                                    });

                                    navigate('/solicitud/prestamo/' + params.tipo)
                                })}
                            >Solicitar un préstamo</button>
                            : null
                    }

                </div>
            </div>

            {
                dataPage.prestamos && dataPage.prestamos['PENDIENTE'] && dataPage.prestamos['PENDIENTE'].length ?
                    <React.Fragment>
                        <div className="col s12  ft-16 txtBold">
                            {
                                dataPage.prestamos['PENDIENTE'].length > 1 ?
                                    'Solicitudes Actuales' :
                                    'Solicitud Actual'
                            }

                        </div>
                        {
                            dataPage.prestamos['PENDIENTE'].map((v, i) => {
                                return (
                                    <div className="row mt-1 rowPrestamoActual" onClick={() => {
                                        setPrestamoSelected(v)
                                    }}>
                                        <div className="col s3 center-align txtBold">
                                            <span>Préstamo {v.IdPrestamo}</span>
                                        </div>
                                        <div className="col s3 center-align">
                                            <span className="badge badgePrestamoActual">{v.Estatus}</span>
                                        </div>
                                        <div className="col s3 center-align">
                                            {processFecha(v.FechaPrestamo)}
                                        </div>
                                        <div className="col s3 center-align txtBold">
                                            ${nWithCommas(parseFloat(v.Monto).toFixed(2))}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </React.Fragment>

                    : null
            }

            {
                dataPage.prestamos && dataPage.prestamos['APLICADO'] && dataPage.prestamos['APLICADO'].length ?
                    <React.Fragment>
                        <div className="col s12 mt-1 ft-16 txtBold">
                            Préstamo{dataPage.prestamos['APLICADO'].length > 1 ? 's' : ''} Activo{dataPage.prestamos['APLICADO'].length > 1 ? 's' : ''}
                        </div>
                        {
                            dataPage.prestamos['APLICADO'].map((v, i) => {
                                return (
                                    <div className="row mt-1 rowPrestamoActivo cursorPointer" onClick={() => {
                                        setPrestamoSelected(v)
                                    }}
                                    >
                                        <div className="col s3 center-align txtBold">
                                            <span>Préstamo {v.IdPrestamo}</span>
                                        </div>
                                        <div className="col s3 center-align">
                                            <span className="badge badgePrestamoActivo">Activo</span>
                                        </div>
                                        <div className="col s3 center-align">
                                            {processFecha(v.FechaPrestamo)}
                                        </div>
                                        <div className="col s3 center-align txtBold">
                                            ${nWithCommas(parseFloat(v.Monto).toFixed(2))}
                                        </div>
                                        <div className="col s12 mt-1"></div>


                                        <div className="col s3 center-align ft-12">
                                            Plazo {v.PlazosLiquidados}/{v.Plazos}
                                        </div>
                                        <div className="col s6 center-align">
                                            <div className="progress">
                                                <div className="determinate" style={{ width: `${getPorcentaje(v.PlazosLiquidados, v.Plazos)}%` }}></div>
                                            </div>
                                        </div>
                                        <div className="col s3 center-align ft-12">
                                            Restante: ${nWithCommas(parseFloat(v.Deuda).toFixed(2))}
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </React.Fragment>

                    : null
            }

            {
                dataPage.prestamos && dataPage.prestamos['HISTORIAL'] && dataPage.prestamos['HISTORIAL'].length ?
                    <React.Fragment>
                        <div className="col s12  ft-16 txtBold">
                            Historial
                        </div>
                        <div className="col s12 mt-1">
                            <MaterialReactTable
                                columns={columns}
                                data={dataPage.prestamos['HISTORIAL']}
                                enableColumnActions={false}
                                enableColumnFilters={false}
                                enableTopToolbar={false}
                                enableBottomToolbar={false}
                                muiTableHeadCellProps={{
                                    align: 'center',
                                    sx: theme => ({
                                        background: '#F6F8FA'
                                    })
                                }}
                                muiTableBodyCellProps={{
                                    align: 'center',

                                    cusor: 'pointer',
                                }}
                                muiTableBodyRowProps={({ row }) => ({
                                    onClick: (event) => {
                                        setPrestamoSelected(row.original)
                                    }
                                })}
                            />;
                        </div>
                    </React.Fragment>

                    : null
            }

            <ul id="slide-detail" className="sidenav" >
                {
                    prestamoSelected ?
                        <React.Fragment>
                            <li className="titlePrestamoSelected">
                                <div className="row mb-0">
                                    <div className="col s6 left-align">
                                        Préstamo {prestamoSelected.IdPrestamo}
                                    </div>
                                    <div className="col s6 right-align">
                                        <i className="material-icons cursorPointer sidenav-close closeSlidePrestamoSelected">close</i>
                                    </div>
                                </div>
                            </li>
                            <li><div className="divider mt-0"></div></li>
                            <li>
                                <div className="row">
                                    <div className="col s12 left-align">
                                        {
                                            prestamoSelected.Estatus === 'Pendiente' ?
                                                <span className="badge badgePrestamoActual left">{prestamoSelected.Estatus}</span>
                                                : null
                                        }

                                        {
                                            prestamoSelected.Estatus === 'Aplicado' ?
                                                <span className="badge badgePrestamoActivo left">Activo</span>
                                                : null
                                        }

                                    </div>
                                    <div className="col s12 center-align">
                                        <HalfDonut prestamoSelected={prestamoSelected} />
                                    </div>
                                    <div className="col s12" style={{
                                        paddingLeft: '22px',
                                        paddingRight: '22px',
                                        marginTop: '-90px'
                                    }}>
                                        {
                                            prestamoSelected.Estatus === 'Pendiente' ?
                                                <div className="row">
                                                    <div className="col s6 left-align ft-14">
                                                        Fecha de solicitud
                                                    </div>
                                                    <div className="col s6 right-align ft-14">
                                                        {processFecha(prestamoSelected.FechaPrestamo)}
                                                    </div>

                                                    <div className="col s6 left-align ft-14">
                                                        Plazos
                                                    </div>
                                                    <div className="col s6 right-align ft-14">
                                                        {prestamoSelected.Plazos}
                                                    </div>
                                                </div>
                                                : null
                                        }

                                        {
                                            prestamoSelected.Estatus === 'Aplicado' ?
                                                <div className="row">
                                                    <div className="col s12 ft-14">
                                                        Último pago
                                                    </div>
                                                    <div className="col s12">
                                                        <div className="row borderUltimoP">
                                                            <div className="col s4 center-align badgePlazos ft-16 txtBold">
                                                                {prestamoSelected.PlazosLiquidados} / {prestamoSelected.Plazos}
                                                            </div>
                                                            {
                                                                prestamoSelected.Amortizaciones.length ?
                                                                    <div className="col s4 center-align ft-16 txt_main-dark-blue">
                                                                        ${nWithCommas(parseFloat(prestamoSelected.Amortizaciones[0].MontoCapital).toFixed(2))}
                                                                    </div>
                                                                    : null
                                                            }

                                                            {
                                                                prestamoSelected.Amortizaciones.length ?
                                                                    <div className="col s4 center-align borderLeftUP ft-16 txt_main-dark-blue">
                                                                        {processFecha(prestamoSelected.Amortizaciones[0].Fecha)}
                                                                    </div>
                                                                    : null
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="col s6 left-align ft-14">
                                                        Fecha de solicitud
                                                    </div>
                                                    <div className="col s6 right-align ft-14">
                                                        {processFecha(prestamoSelected.FechaPrestamo)}
                                                    </div>
                                                    {
                                                        prestamoSelected.Amortizaciones.length ?
                                                            <React.Fragment>
                                                                <div className="col s6 left-align ft-14">
                                                                    Fecha de Inicio
                                                                </div>
                                                                <div className="col s6 right-align ft-14">
                                                                    {processFecha(prestamoSelected.Amortizaciones[0].Fecha)}
                                                                </div>
                                                            </React.Fragment>
                                                            : null}
                                                    <div className="col s6 left-align ft-14">
                                                        Plazos
                                                    </div>
                                                    <div className="col s6 right-align ft-14">
                                                        {prestamoSelected.Plazos}
                                                    </div>
                                                    <div className="col s6 left-align ft-14">
                                                        Tasa de interés
                                                    </div>
                                                    <div className="col s6 right-align ft-14">
                                                        {prestamoSelected.Tasa}%
                                                    </div>
                                                    <div className="col s12">
                                                        <div className="divider" />
                                                    </div>
                                                    <div className="col s6 left-align ft-14">
                                                        Interés pagado
                                                    </div>
                                                    <div className="col s6 right-align ft-14">
                                                        ${nWithCommas(parseFloat(prestamoSelected.Interespagado).toFixed(2))} mx
                                                    </div>
                                                    <div className="col s6 left-align ft-14">
                                                        Capital pagado
                                                    </div>
                                                    <div className="col s6 right-align ft-14">
                                                        ${nWithCommas(parseFloat(prestamoSelected.CapitalPagado).toFixed(2))} mx
                                                    </div>
                                                    <div className="col s6 left-align capitalPendiente ft-14">
                                                        Capital pendiente
                                                    </div>
                                                    <div className="col s6 right-align capitalPendiente ft-14">
                                                        ${nWithCommas(parseFloat(prestamoSelected.Deuda).toFixed(2))} mx
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </li>

                        </React.Fragment>
                        : null
                }


            </ul>
        </div>
    )
}


export default Prestamos;