import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { onlyDigits, encryptAES } from "../utils";
import { CALCULADORA_73, CALCULADORA_97 } from "../properties";
import ResultCalculadora from "../components/resultCalculadora";
import { useDispatch } from "react-redux";
import { toogleLoad } from "../redux/slices/appslice";
import {useAnalytics} from '../AnalyticsContext'
import { logEvent } from "firebase/analytics";
import M from 'materialize-css';


const calculoRetiro = [
    {
        titulo: 'Calculadora (Ley 73)',
        opt: 1
    },
    {
        titulo: 'Calculadora (Ley 97)',
        opt: 2
    }
]

function CalculadoraRetiro({fromLogin}) {

    const analytics = useAnalytics();
    const dispatch = useDispatch();
    const [content, setContent] = useState(calculoRetiro[0])
    const [result, setResult] = useState(false);
    const [helpText, setHelpText] = useState(false);
    const [rangosEdades, setRangosEdades] = useState({
        edadActual: [],
        edadJubilar: [],
        perPlanPension: [],
        perEmp: [],
        renEsp: []
    })
    const navigate = useNavigate();



    const [dataform, setDataForm] = useState({});

    useEffect(() => {

        logEvent(analytics, "calculadora_pension", {
            screen_name: "Hamburguesa",
        });

     
        document.body.classList.add('bodyUser')
        
      
        M.Modal.init( document.getElementById('modalHelper'), {
            onCloseEnd: ()=>{
                setHelpText(false)
            }

        });
        
        return () => {
            if(!fromLogin){
                document.body.classList.remove('bodyUser')
                document.body.classList.add('bodyLogin')
            }
        }

       
    }, [])

    useEffect(()=>{
        if(helpText){
            M.Modal.getInstance(document.getElementById('modalHelper')).open()
        }
    },[helpText])

    useEffect(() => {
        if (content.opt == 1) {
            setRangosEdades({
                ...rangosEdades,
                edadActual: rangosEdad(42, 74),
                edadJubilar: rangosEdad(60, 75),
                perPlanPension: rangosEdad(0, 15),
                perEmp: rangosEdad(0, 15),
                renEsp: rangosEdad(5, 7)
            })
        } else {
            setRangosEdades({
                ...rangosEdades,
                edadActual: rangosEdad(18, 70),
                edadJubilar: rangosEdad(60, 75),
                perPlanPension: rangosEdad(0, 15),
                perEmp: rangosEdad(0, 15),
                renEsp: rangosEdad(5, 7)
            })
        }

        setDataForm({})
        setResult(false);

    }, [content])

    function rangosEdad(edadMinima, edadMax) {
        let edades = []
        for (let index = edadMinima; index <= edadMax; index++) {
            edades.push(index)
        }
        return edades
    }

    function calcularRetiro(e) {
        e.preventDefault()
       

        dispatch(toogleLoad())


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ DatosEncriptados: encryptAES(dataform) })
        };

        let url = CALCULADORA_97;
        if (content.opt === 1) {
            url = CALCULADORA_73;
        }

        fetch(url, requestOptions)
            .then(response => response.json())
            .then((data) => {
                
                if (data && data.success) {
                    setResult(data.response)
                }
                dispatch(toogleLoad())
            }).catch((e) => {
                dispatch(toogleLoad())
            });

    }

    function showFormLey() {
        if (content.opt === 1) {
            return (
                <form onSubmit={calcularRetiro} className="row">
                    <div className="col s12 left-align ft-18 txtBold">
                        Información Básica:
                    </div>
                    <div className="col s12 ft-14 mt-2">
                        ¿Cuál es tu edad actual?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {
                                setDataForm({ ...dataform, edadActual: parseInt(e.target.value) })
                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.edadActual.map((v, i) => <option value={v} key={i}>{v}</option>)
                            }
                        </select>
                    </div>
                    <div className="col s1">

                    </div>
                    <div className="col s12  ft-14">
                        ¿A qué edad te quieres jubilar?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {
                                setDataForm({ ...dataform, edadJubilar: parseInt(e.target.value) })
                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.edadJubilar.map((v, i) => <option value={v} key={i}>{v}</option>)
                            }
                        </select>
                    </div>
                    <div className="col s1"
                    ></div>
                    <div className="col s12 mb-0  ft-14">
                        ¿Cuál es tu salario bruto mensual?
                    </div>
                    <div className="input-field col s11 mt-0">
                        <input placeholder="Salario bruto mensual" id="salario_bruto_73" type="text"
                            value={dataform.salarioBruto || ''}
                            onChange={(e) => {
                                if (onlyDigits(e.target.value)) {
                                    setDataForm({ ...dataform, salarioBruto: parseFloat(e.target.value) })
                                }
                            }}
                        />
                    </div>
                    <div className="col s1">
                        <i className="material-icons helpCalc "
                        onClick={()=>{
                            setHelpText({
                                titulo: 'Salario Bruto',
                                txt: 'Es tu salario total antes de que se produzcan las retenciones y cotizaciones a la Seguridad Social.'
                            })
                        }}
                        >help_outline</i>
                    </div>

                    <div className="col s12  ft-14">
                        ¿Tienes esposa/o ó concubina/o?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {
                                setDataForm({ ...dataform, estadoCivil: parseInt(e.target.value) })
                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            <option value={1} >Sí</option>
                            <option value={0} >No</option>
                        </select>
                    </div>
                    <div className="col s1">

                    </div>

                    <div className="col s12 mb-0  ft-14">
                        ¿Semanas cotizadas en el IMSS?
                    </div>
                    <div className="input-field col s11 mt-0">
                        <input placeholder="Número de semanas cotizadas en el IMSS" id="salario_bruto_73" type="text"
                            value={dataform.semanasCotizadas || ''}
                            onChange={(e) => {
                                if (onlyDigits(e.target.value)) {
                                    setDataForm({ ...dataform, semanasCotizadas: parseInt(e.target.value) })
                                }
                            }}
                        />
                    </div>
                    <div className="col s1">
                        <i className="material-icons helpCalc"
                        onClick={()=>{
                            setHelpText({
                                titulo: 'Semanas cotizadas',
                                txt: 'Conoce cuantas semanas llevas cotizadas en la página del IMSS. Es necesario tu CURP, NSS y un correo electrónico.'
                            })
                        }}
                        >help_outline</i>
                    </div>

                    <div className="col s12 mb-0  ft-14">
                        ¿Ahorro actual para el retiro?
                    </div>
                    <div className="input-field col s11 mt-0">
                        <input id="salario_bruto_73" type="text"
                            value={dataform.ahorroActual || ''}
                            onChange={(e) => {
                                if (onlyDigits(e.target.value)) {
                                    setDataForm({ ...dataform, ahorroActual: parseInt(e.target.value) })
                                }
                            }}
                        />
                    </div>
                    <div className="col s1">
                        <i className="material-icons helpCalc"
                        onClick={()=>{
                            setHelpText({
                                titulo: 'Ahorro actual',
                                txt: 'Se refiere a que cantidad tienes ahorrada en algún plan de pensiones u otro producto para la jubilación.'
                            })
                        }}
                        >help_outline</i>
                    </div>

                    <div className="col s12 ft-14">
                        ¿Tú aportación mensual al Plan de Pensiones?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {

                                setDataForm({ ...dataform, aportMensualPlan: parseInt(e.target.value) })
                            }}
                        >
                            {
                                rangosEdades.perPlanPension.map((v, i) =>
                                    <option value={v} key={i}>{v}%</option>
                                )
                            }

                        </select>
                    </div>
                    <div className="col s1">

                    </div>

                    <div className="col s12 ft-14">
                        ¿Aportación mensual por la empresa?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {

                                setDataForm({ ...dataform, aportMensualEmp: parseFloat(e.target.value) })

                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.perEmp.map((v, i) =>
                                    <option value={v} key={i}>{v}%</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col s1">
                    </div>

                    <div className="col s12 ft-14">
                        ¿Rendimiento esperado?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {

                                setDataForm({ ...dataform, rendimientoEsp: parseInt(e.target.value) })
                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.renEsp.map((v, i) =>
                                    <option value={v} key={i}>{v}%</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col s1">
                        <i className="material-icons helpCalc"
                        onClick={()=>{
                            setHelpText({
                                titulo: 'Rendimiento esperado',
                                txt: 'Rendimiento anual esperado de la inversión.'
                            })
                        }}
                        >help_outline</i>
                    </div>

                    <div className="col s4 left-align mt-1">
                        <button type="submit" className="btn btn-webSi-primary waves-effect light">Calcular</button>
                    </div>
                    <div className="col s8 left-align mt-1"></div>
                </form>

            )
        } else {
            return (
                <form onSubmit={calcularRetiro} className="row">
                    <div className="col s12 left-align ft-18 txtBold">
                        Información Básica:
                    </div>
                    <div className="col s12  ft-14 mt-2">
                        ¿Cuál es tu edad actual?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {
                                setDataForm({ ...dataform, edadActual: parseInt(e.target.value) })
                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.edadActual.map((v, i) => <option value={v} key={i}>{v}</option>)
                            }
                        </select>
                    </div>
                    <div className="col s1">

                    </div>
                    <div className="col s12  ft-14">
                        ¿A qué edad te quieres jubilar?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {
                                setDataForm({ ...dataform, edadJubilar: parseInt(e.target.value) })
                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.edadJubilar.map((v, i) => <option value={v} key={i}>{v}</option>)
                            }
                        </select>
                    </div>
                    <div className="col s1">

                    </div>
                    <div className="col s12 mb-0  ft-14">
                        ¿Cuál es tu salario bruto mensual?
                    </div>
                    <div className="input-field col s11 mt-0">
                        <input placeholder="Salario bruto mensual" id="salario_bruto_73" type="text"
                            value={dataform.salarioBruto || ''}
                            onChange={(e) => {
                                if (onlyDigits(e.target.value)) {
                                    setDataForm({ ...dataform, salarioBruto: parseFloat(e.target.value) })
                                }
                            }}
                        />
                    </div>
                    <div className="col s1">
                        <i className="material-icons helpCalc"
                        onClick={()=>{
                            setHelpText({
                                titulo: 'Salario Bruto',
                                txt: 'Es tu salario total antes de que se produzcan las retenciones y cotizaciones a la Seguridad Social.'
                            })
                        }}
                        >help_outline</i>
                    </div>

                    <div className="col s12 mb-0  ft-14">
                        ¿Cuánto tienes en tu Afore?
                    </div>
                    <div className="input-field col s11 mt-0">
                        <input placeholder="Ahorro actual en tú Afore" id="salario_bruto_73" type="text"
                            value={dataform.ahorroAfore || ''}
                            onChange={(e) => {
                                if (onlyDigits(e.target.value)) {
                                    setDataForm({ ...dataform, ahorroAfore: parseFloat(e.target.value) })
                                }
                            }}
                        />
                    </div>
                    <div className="col s1">
                        <i className="material-icons helpCalc"
                        onClick={()=>{
                            setHelpText({
                                titulo: 'Acumulado en mi Afore',
                                txt: 'Consulta o solicita los estados de cuenta que tu entidad administrativa te envía cada mes.'
                            })
                        }}
                        
                        >help_outline</i>
                    </div>

                    <div className="col s12 mb-0  ft-14">
                        ¿Ahorro actual para el retiro?
                    </div>
                    <div className="input-field col s11 mt-0">
                        <input id="salario_bruto_73" type="text"
                            value={dataform.ahorroPlan || ''}
                            onChange={(e) => {
                                if (onlyDigits(e.target.value)) {
                                    setDataForm({ ...dataform, ahorroPlan: parseInt(e.target.value) })
                                }
                            }}
                        />
                    </div>
                    <div className="col s1">
                        <i className="material-icons helpCalc"
                        onClick={()=>{
                            setHelpText({
                                titulo: 'Ahorro actual',
                                txt: 'Se refiere a que cantidad tienes ahorrada en algún plan de pensiones u otro producto para la jubilación.'
                            })
                        }}
                        >help_outline</i>
                    </div>

                    <div className="col s12 ft-14">
                        ¿Tú aportación mensual al Plan de Pensiones?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {

                                setDataForm({ ...dataform, aportMensualPlan: parseInt(e.target.value) })
                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.perPlanPension.map((v, i) =>
                                    <option value={v} key={i}>{v}%</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col s1">

                    </div>

                    <div className="col s12 ft-14">
                        ¿Aportación mensual por la empresa?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {

                                setDataForm({ ...dataform, aportMensualEmp: parseInt(e.target.value) })

                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.perEmp.map((v, i) =>
                                    <option value={v} key={i}>{v}%</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col s1">
                    </div>

                    <div className="col s12 ft-14">
                        ¿Rendimiento esperado?
                    </div>
                    <div className="input-field col s11">
                        <select className="browser-default"
                            onChange={(e) => {

                                setDataForm({ ...dataform, rendimientoEsp: parseInt(e.target.value) })
                            }}
                        >
                            <option value=''>Selecciona una opción</option>
                            {
                                rangosEdades.renEsp.map((v, i) =>
                                    <option value={v} key={i}>{v}%</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col s1">
                        <i className="material-icons helpCalc"
                        onClick={()=>{
                            setHelpText({
                                titulo: 'Rendimiento esperado',
                                txt: 'Rendimiento anual esperado de la inversión.'
                            })
                        }}
                        >help_outline</i>
                    </div>

                    <div className="col s4 left-align mt-1">
                        <button type="submit" className="btn btn-webSi-primary waves-effect light">Calcular</button>
                    </div>
                    <div className="col s8 left-align mt-1"></div>
                </form>

            )
        }
    }



    return (
        <div className="container">
            <div className="row">
                <div className="col s12 left-align mt-2">
                    {
                         !fromLogin ? 
                         <p className='cursorPointer'
                         onClick={() => {
                             navigate('/educacion_financiera')
                         }}
                     ><i className='material-icons left'>keyboard_backspace</i> Educación Financiera</p>
                     : null
                    }
                  
                    <h5 className="txtBold">Calcula tu Pensión</h5>
                </div>

                <div className="col s12 mt-2">
                    <div className="row ">
                        <div className="col s3">
                            <div className="row btnSide">

                                {
                                    calculoRetiro.map((v, i) => {
                                        return (
                                            <div className="col s12" key={i}>
                                                <button className={`btn waves-effect light btnAct white ${content.titulo === v.titulo ? 'optSelected' : ''} ft-14`}
                                                    key={i}
                                                    onClick={() => {
                                                        setContent(v)
                                                    }}
                                                >{v.titulo}</button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col s9">
                            {
                                result ?
                                    <ResultCalculadora data={result} opt={content.opt} 
                                    helper = {()=>{
                                        setHelpText({
                                            titulo: 'Cálculo de pensión',
                                            txt: content.opt === 1 ? `Cálculos estimados y considerando  los datos introducidos y supuestos de Ley del Seguro Social vigentes hasta el 30 de junio de 1997. El resultado no es vinculatorio a trámites de pensión, ni tiene validez alguna`
                                            : 'Los cálculos estiman una esperanza de vida de 85 años. Los resultados son ilustrativos y no representan un dictamen con valor oficial.'
                                        })
                                    }}
                                    />
                                    : showFormLey()
                            }
                        </div>

                    </div>
                </div>



            </div>

            <div id="modalHelper" className="modal">
                <div className="modal-content">
                    {
                        helpText ? 
                            <React.Fragment>
                                <p className="txtBold ft-14">  {helpText.titulo}</p>
                                <p>{helpText.txt}</p> 
                            </React.Fragment>

                        : ''
                    }
                </div>
            </div>
        </div>
    )

}

export default CalculadoraRetiro;