
import { useSelector } from 'react-redux';
import {useAnalytics} from '../AnalyticsContext'
import { logEvent } from "firebase/analytics";
import { useEffect } from 'react';

function Terminos({centerTitle}) {

    const analytics = useAnalytics();
    const appData = useSelector((state) => state.appData);

    useEffect(()=>{
        logEvent(analytics, "L_consulta_términos", {
            screen_name: "Hamburguesa",
        });
    },[])

    if (appData.legales && appData.legales.Terminos) {

        return (
            <div className="row contentLegal">
                <div className={`col s12 left-align labelNavy ft-18 txtBold ${centerTitle ? 'center-align':''}`}>
                    { decodeURIComponent(escape(atob( appData.legales.Terminos.titulo )))}
                </div>
                <div className={`col s12 left-align ft-16 fw-400 mt-1 ${centerTitle ? '':'wrapDataLegales'} `}>
                { decodeURIComponent(escape(atob( appData.legales.Terminos.contenido )))}
                </div>
            </div>
        )

    }
    return (<div></div>)
}


export default Terminos;