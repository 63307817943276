import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router"
import { ICONS } from "../assets/icons";
import { useSelector, useDispatch } from "react-redux";
import M from 'materialize-css';
import { NumericFormat } from 'react-number-format';
import { nWithCommas, convertirCamelCase, TEXT_FIN_SESION_JWT } from "../utils";
import {
    showModalFinSesion,
    toogleLoad, setSaldosPrestamos, setSaldosMovimientos
} from "../redux/slices/appslice";
import { solicitarRetiro } from "../ws";
import { useAnalytics } from "../AnalyticsContext";
import { logEvent } from "firebase/analytics";

function SolicitudRetiro() {

    const analytics = useAnalytics();
    const dispatch = useDispatch()
    const appData = useSelector((state) => state.appData)
    const TEXTO_PASOS = {
        1: 'Retiro',
        2: 'Confirmación',
        3: 'Terminar Solicitud'
    }

    const navigate = useNavigate()
    const params = useParams();

    const [dataPage, setDataPage] = useState({
        titulo: '',
        to_return: ''
    });



    const [paso, setPaso] = useState(1);
    const [monto, setMonto] = useState()
    const [errorMsg, setErrorMsg] = useState(false)

    const [tipoRetiro, setTipoRetiro] = useState(false);
    const [msgExito, setMsgExito] = useState(false);

    useEffect(() => {

        logEvent(analytics, "acceso_solicitud_retiro_ca", {
            screen_name: "Solicitud de Retiro",
        });


        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems, {});
    }, [])



    useEffect(() => {
        if (params.tipo && params.tipo === 'fondodeahorro') {

            setDataPage({ titulo: 'Fondo de Ahorro', to_return: '/fondodeahorro' });

        } else if (params.tipo && params.tipo === 'cajadeahorro') {

            setDataPage({ titulo: 'Caja de Ahorro', to_return: '/cajadeahorro' });

        } else {
            navigate('/')
        }

    }, [params])

    function getTextButton() {
        if (paso < 2) {
            return 'Continuar'
        }

        if (paso === 2) {
            return 'Enviar Solicitud'
        }

        if (paso === 3) {
            return 'Salir'
        }
    }


    const solicitaRetiro = async () => {

        logEvent(analytics, "solicitud_retiro_ca", {
            screen_name: "Solicitud de Retiro",
        });
       
        dispatch(toogleLoad(true));
        try {
            
            let resultSolicitudRetiro = await solicitarRetiro(appData, { tipoRetiro, monto }, ()=>{
                dispatch(showModalFinSesion({texto:TEXT_FIN_SESION_JWT}))
            });
    
            if (resultSolicitudRetiro) {

                if(resultSolicitudRetiro.BanderaExito && resultSolicitudRetiro.Mensaje.trim() !== ""){
                    setMsgExito(resultSolicitudRetiro.Mensaje.trim())
                    dispatch(setSaldosPrestamos(false));
                    dispatch(setSaldosMovimientos(false));
                    setPaso(3); 
                }

            }
        } catch (error) {

        }

        dispatch(toogleLoad(false));
    }

    return (
        <div className="container mt-2" style={{ minHeight: '90vh' }}>
            <div className="row">

                <div className="col s4 left-align">
                    <p className='cursorPointer'
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className='material-icons left'>keyboard_backspace</i> <span className="ft-16 txtBold">Solicitar Retiro </span></p>
                </div>

                <div className="col s4 center-align ft-14" style={{ paddingTop: '17px' }}>
                    {paso}. {TEXTO_PASOS[paso]}
                </div>

                <div className="col s4 right-align" style={{ paddingTop: '10px' }}>
                    <a className="waves-effect waves-light btn ft-14 txt_main-dark-blue white br-12 btnCancel txtBold"
                        onClick={() => {
                            navigate(dataPage.to_return)
                        }}
                    ><i className="material-icons left ft-14 txt_main-dark-blue">clear</i>Cancelar</a>
                </div>


                <div className="col s4 center-align mt-1">
                    <div className="row">
                        <div className="col s4">
                            <img src={ICONS.elipseLleno} alt="paso" />
                        </div>
                        <div className="col s4">
                            <img src={paso >= 2 ? ICONS.elipseLleno : ICONS.elipseVacio} alt="paso" />
                        </div>
                        <div className="col s4">
                            <img src={paso >= 3 ? ICONS.elipseLleno : ICONS.elipseVacio} alt="paso" />
                        </div>

                    </div>
                </div>
                <div className="col s4"></div>

                <div className="col s12 mt-1 ">
                    <div className="row">
                        {
                            paso === 3 ?
                                <div className="col s12">
                                    <div className="row center-align">
                                        <div className="col s12 mt-4">
                                            <img src={ICONS.solicitudAprobada} alt="solicitud aprobada" />
                                        </div>
                                        <div className="col s12 ft-24 mt-2 colorDarkBlue">
                                            {
                                                msgExito ? msgExito : 'La solicitud de tu Retiro ha sido enviada'
                                            }

                                        </div>
                                        <div className="col s12 ft-16 ">

                                            <p className="mb-0">Retiro sujeto a autorización.</p>
                                            {/* <p className="mt-0">Puedes revisar el estatus de tu solicitud en</p> */}

                                            {/* <p className="linkCustom"
                                                onClick={() => {

                                                    if (appData.configPrestamo && appData.configPrestamo.Producto.length) {
                                                        let tipoCli = appData.configPrestamo.Producto[0].TipoCliente.toUpperCase();
                                                        if (tipoCli === 'FONDO DE AHORRO') {
                                                            navigate('/prestamos/fondodeahorro');
                                                        } else if (tipoCli === 'CAJA DE AHORRO') {
                                                            navigate('/prestamos/cajadeahorro');
                                                        } else {
                                                            navigate('/');
                                                        }


                                                    }


                                                }}
                                            >Aceptar</p> */}
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            paso === 2 ?
                                <div className="col s12">
                                    <div className="row center-align">
                                        <div className="col s12 labelTxt ft-16">
                                            Tipo de Retiro
                                        </div>
                                        <div className="col s12 ft-24 txtBold">
                                            {tipoRetiro ? convertirCamelCase(appData.configRetiro.TipoRetiros.filter((v, i) => v.IdTipoRetiro === parseInt(tipoRetiro))[0].Descripcion) : ''}
                                        </div>

                                        <div className="col s12 labelTxt ft-16 mt-1">
                                            Monto
                                        </div>
                                        <div className="col s12 ft-24 txtBold">
                                            $ {nWithCommas(parseFloat(monto).toFixed(2))}
                                        </div>

                                        <div className="col s12 labelTxt ft-16 mt-1">
                                        Depósito del Préstamo
                                        </div>
                                        <div className="col s12 ft-24 txtBold">
                                            Cuenta de Cheques
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        <div className="col s12 lef-align labelTxt" hidden={paso >= 2}>
                            Tipo de Retiro
                        </div>
                        <div className="input-field col s12" hidden={paso >= 2}>
                            <select value={tipoRetiro} onChange={(e) => {
                                setTipoRetiro(e.target.value)
                            }}>
                                <option value="">Selecciona una opción</option>
                                {appData.configRetiro && appData.configRetiro.TipoRetiros ?
                                    appData.configRetiro.TipoRetiros.map((v, i) => {
                                        return <option key={i} value={v.IdTipoRetiro}
                                        >{v.Descripcion}</option>
                                    })

                                    : <option></option>}
                            </select>

                        </div>
                        <div className="col s12 mt-1 labelTxt" hidden={paso >= 2}>
                            Importe
                        </div>
                        <div className="input-field col s12" hidden={paso >= 2}>
                            <NumericFormat
                                id="monto"
                                thousandSeparator={','}
                                decimalSeparator="."
                                value={monto}
                                isNumericString
                                decimalScale={2}
                                fixedDecimalScale
                                placeholder="$0.00"
                                prefix="$"
                                onValueChange={(values) => {

                                    let errorMsg = false;

                                    if (parseFloat(values.value) <= 0) {
                                        errorMsg = "Indique un monto válido mayor a $0.00.";
                                    }

                           

                                    setErrorMsg(errorMsg);
                                    setMonto(values.value);

                                }}
                            />
                            {errorMsg ?
                                <span className="helper-text red-text text-darken-3" >{errorMsg}</span>
                                : null}
                        </div>
                    </div>

                </div>
                {
                    paso === 2 ?
                        <div className="col s12 center-align">
                            <button className="backButton"
                                onClick={() => {
                                    let currentPaso = paso;
                                    if (currentPaso === 2) {
                                        currentPaso = currentPaso - 1;
                                        setPaso(currentPaso);
                                    }
                                }}>Atrás</button>
                        </div>

                        : null
                }

                <div className="col s12 center-align">
                    <button className="btn btnContinueF5 mt-1" 
                        onClick={() => {

                            let currentPaso = paso;

                            if (paso === 1 && tipoRetiro && tipoRetiro !== "") {
                              
                                if (monto === "" || parseFloat(monto) <= 0) {
                                    M.toast({ html: "<i class='material-icons left'>warning</i>Ingrese un importe válido.", classes: 'yellow accent-4 black-text txtBold' });
                                } else if (parseFloat(monto) > 0) {

                                    logEvent(analytics, "Confirmación_solicitud_retiro_ca", {
                                        screen_name: "Solicitud de Retiro",
                                    });

                                    currentPaso = currentPaso + 1;
                                }
                            } else if (paso === 1 && !tipoRetiro || tipoRetiro === "") {

                                M.toast({ html: "<i class='material-icons left'>warning</i>Seleccione un tipo de retiro.", classes: 'yellow accent-4 black-text txtBold' });
                            }

                            if (paso === 2) {
                                solicitaRetiro();
                            }

                            if (paso === 3) {

                                // if (appData.configPrestamo && appData.configPrestamo.Producto.length) {
                                //     let tipoCli =  appData.configPrestamo.productoConfig.TipoCliente.toUpperCase();
                                //     if (tipoCli === 'FONDO DE AHORRO') {
                                //         navigate('/fondodeahorro');
                                //     } else if (tipoCli === 'CAJA DE AHORRO') {
                                //         navigate('/cajadeahorro');
                                //     } else {
                                //         navigate('/');
                                //     }

                                // }else{

                                //     navigate('/');
                                // }
                                if(dataPage.to_return && dataPage.to_return.trim() !== ''){
                                    navigate(dataPage.to_return)
                                }else{
                                    navigate('/cajadeahorro');   
                                }
                                
                            } else {
                                setPaso(currentPaso)
                            }

                        }}
                    >{
                            getTextButton()
                        }</button>
                </div>
            </div>
        </div>
    )
}

export default SolicitudRetiro;