
import React from 'react';
import logo from '../assets/pdfs/logoReporte.png';
import iconoFondo from '../assets/pdfs/iconoFondo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { nWithCommas, processFechaGuion, processFechaNM } from '../utils';
import { BASE_URL } from "../properties";
import {
    obtenerFechaActualString, encryptAES, separarConceptos,
    agregaAportacion, capitalizeFirstLetter, eliminarAcentos, negativos,
    obtenerPrimerDiaDelMes

} from '../utils';
import { toogleLoad, setTokenSesion, } from "../redux/slices/appslice";


function PdfFondoAhorro({ movToShow, id, primerFecha }) {
    const dispatch = useDispatch();
    const appData = useSelector((state) => state.appData)
    const [saldosFecha, setSaldosFecha] = useState(false);
    const [cuentas, setCuentas] = useState([]);
    const [movimientos, setMovimientos] = useState([]);
    const [dataSaldos, setDataSaldos] = useState([]);
    const [aportacionesSeparadas, setAportacionesSeparadas] = useState(false);
    const [datosEmpleado, setDatosEmpleado] = useState(false);
    const [sumaSaldoInicial, setSumaSaldoInicial] = useState(0);


    useEffect(() => {

        if (appData.saldosFecha) {

            for (let saldo of appData.saldosFecha) {
                if (saldo.Producto && saldo.Producto.length) {
                    if (saldo.Producto[0].TipoCliente.toUpperCase() === 'FONDO DE AHORRO') {

                        setSaldosFecha(saldo);
                    }
                }
            }
        }


        if (appData.saldosTrimestrales && appData.saldosTrimestrales.saldosAgrupadosXPlan && appData.saldosTrimestrales.saldosAgrupadosXPlan.length) {
            for (let saldos of appData.saldosTrimestrales.saldosAgrupadosXPlan) {
                if (saldos.Producto && saldos.Producto.TipoCliente.toUpperCase() === 'FONDO DE AHORRO') {


                    let total_rendimientos = saldos.saldos.reduce((total, elemento) => total + elemento.rendimiento, 0);
                    let total_saldo = saldos.saldos.reduce((total, elemento) => total + elemento.saldo, 0);


                    setDataSaldos({
                        ...dataSaldos,
                        saldos: saldos.saldos,
                        total_rendimientos: total_rendimientos,
                        total_saldo: total_saldo
                    })
                }
            }
        }

        if (appData.saldosTrimestrales && appData.saldosTrimestrales.saldosAgrupadosXCuenta) {

            for (let st of appData.saldosTrimestrales.saldosAgrupadosXCuenta) {
                if (st.Producto && st.Producto.TipoCliente.toUpperCase() === 'FONDO DE AHORRO') {
                    if (st.saldos && st.saldos.length) {

                        setSumaSaldoInicial(parseFloat(st.saldos.reduce((accumulator, item) => accumulator + item.saldoini, 0)));

                        setAportacionesSeparadas(separarConceptos(st.saldos));
                    }
                }
            }
        }

    }, []);


    useEffect(() => {
        if (movToShow && Object.keys(movToShow).length) {
            let movi = [];
            for (let movK of Object.keys(movToShow).reverse()) {
                for (let mov of movToShow[movK]) {
                    movi.push(mov);
                }
            }

            setMovimientos(movi);

        }
    }, [movToShow])





    const getSaldosPorCuenta = async () => {
        dispatch(toogleLoad(true));

        const dataUserProducto = saldosFecha.Producto;

        if (dataUserProducto.length && appData.token && appData.tokenSesion) {

            let dataToEncrypt = {
                "Usuario": appData.user.usuarioLogin,
                "IdUsuario": `${appData.user.IdUsuario}`,
                "Sesion": appData.user.Sesion,
                "IdCliente": dataUserProducto[0].IdCliente,
                "IdEmpleado": dataUserProducto[0].IdEmpleado,
                "FechaIni": "20240801",
                "FechaFin": obtenerFechaActualString(),
                "TodaLaHistoria": "True",
                "SistemaOperativo": "web",
                "AppID": "SAF_APP",
                "token": appData.tokenSesion,
                "refresh_token": appData.token.refresh_token,
                "DeviceToken": "dv1"
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': appData.idToken
                },
                body: JSON.stringify({ DatosEncriptados: encryptAES(dataToEncrypt) })
            };

            fetch(`${BASE_URL}/saldos/saldosYMovimientos`, requestOptions)
                .then(response => response.json())
                .then((data) => {


                    if (data.Datos && data.JWT && data.Datos && data.Datos.ListaCuentas && data.Datos.ListaMovimientos) {

                        setDatosEmpleado(data.Datos);
                        setCuentas(data.Datos.ListaCuentas);

                        //setMovimientos(data.Datos.ListaMovimientos);

                        dispatch(setTokenSesion(data.JWT.current_token))

                        dispatch(toogleLoad(false));


                    } else {
                        dispatch(toogleLoad(false));
                    }
                }).catch((e) => {

                    dispatch(toogleLoad(false));

                });
        }


    }

    const getTotalFondo = () => {

        let total = 0;

        if (Object.keys(aportacionesSeparadas).length) {

            for (let k of Object.keys(aportacionesSeparadas)) {
                total += parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.saldo, 0));

            }
        }

        return total;

    }




    return (
        <div className="row" id={id}>
            <div className='col s4'>
                <img src={logo} alt="logo" style={{ maxHeight: '55px' }} />
            </div>
            <div className='col s8'>
                <div className='row'>
                    <div className='col s12 center-align' style={{
                        background: '#ffa400',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        color: 'white', height: '55px', paddingTop: '1rem'
                    }}>
                        Fondo de Ahorro
                    </div>
                    <div className='col s12 center-align ft-12'>
                        Valores Mexicanos Casa de Bolsa, S.A. de C.V.
                    </div>
                </div>

            </div>
            <div className='col s12'>

            </div>

            <div className='col s8 offset-s4' style={{ color: '#002c5e', fontWeight: '600', fontSize: '14px' }}>
                {
                    Object.keys(movToShow).length && saldosFecha ?
                        `Periodo del ${obtenerPrimerDiaDelMes(processFechaGuion(primerFecha.Fecha))} al ${processFechaGuion(saldosFecha.FechaOP)}`
                        : null
                }
            </div>

            <div className='col s8 offset-s4 mt-1' style={{ color: '#002c5e', fontWeight: 'bold', fontSize: '18px' }}>
                Reporte de Saldos y Movimientos
            </div>

            <div className='col s12 mt-1'>

            </div>

            <div className='col s4'>
                <div className='row'>
                    <div className='col s12'>
                        <span className='secondaty_cyan boldText ft-12'>{appData.user.Nombre + ' ' + appData.user.ApPaterno + ' ' + appData.user.ApMaterno}</span>
                    </div>

                    <div className='col s12'>
                        <span className='boldText ft-12'># Colaborador:  {datosEmpleado ? datosEmpleado.Clave : ''}</span>
                    </div>
                    <div className='col s12'>
                        <span className='boldText ft-12'>RFC: {datosEmpleado ? datosEmpleado.RFC : ''}</span>
                    </div>
                    <div className='col s12'>
                        <span className='boldText ft-12'>CURP: {datosEmpleado ? datosEmpleado.CURP : ''}</span>
                    </div>
                </div>

            </div>
            <div className='col s8 ft-12' >
                <div className='row'>
                    <div className='col s12'>
                        <span className='boldText secondaty_cyan ft-16'>Mi Plan</span>
                    </div>
                    <div className='col s6 left-align boldText '>
                        Saldo Inicial:
                    </div>
                    <div className='col s6 right-align '>
                        ${nWithCommas(sumaSaldoInicial.toFixed(2))}
                    </div>

                    <div className='col s6 left-align boldText'>Aportaciones:</div>
                    <div className='col s6 right-align ' >${nWithCommas(parseFloat(saldosFecha.aportaciones).toFixed(2))}</div>
                    <div className='col s6 left-align boldText'>Préstamos:</div>
                    <div className='col s6 right-align ' >${nWithCommas(parseFloat(saldosFecha.SaldoPrestamos).toFixed(2))}</div>
                    <div className='col s6 left-align boldText'>Ajustes:</div>
                    <div className='col s6 right-align '>{negativos(`${nWithCommas(parseFloat(saldosFecha.Retiros).toFixed(2))}`)}</div>
                    <div className='col s6 left-align boldText'>Plusvalía/Minusvalía:</div>

                    <div className='col s6 right-align '>{negativos(`$${nWithCommas(parseFloat(saldosFecha.rendimiento).toFixed(2))}`)}</div>

                    <div className='col s12 white-text mt-1  right-align'>
                        <div style={{ background: '#ff606b', height: '20px', paddingTop: '1px' }}>
                            <span className='boldText'>Saldo Total: </span> <span style={{ marginRight: '2px' }}>${nWithCommas(parseFloat(saldosFecha.TotalLiquidar).toFixed(2))}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col s4' style={{ paddingLeft: '0px' }}>
                <div className='boldText white-text' style={{
                    background: '#ffa400',
                    paddingLeft: '3px'
                }}>
                    Resumen de Mi Fondo
                </div>
            </div>
            <div className='col s8'>
            </div>
           
            <div className='col s12 mt-1'></div>
            <div className='col s2 ft-10 center-align containerResumenBlue'>
                Concepto
            </div>
            <div className='col s2 ft-10 center-align containerResumenBlue'>
                Saldo Inicial
            </div>
            <div className='col s2 ft-10 center-align containerResumenBlue'>
                 Aportaciones
            </div>
            <div className='col s2 ft-10 center-align containerResumenBlue'>
                 Rendimientos
            </div>
            <div className='col s2 ft-10 center-align containerResumenBlue'>
                 Ajustes
            </div>
            <div className='col s2 ft-10 center-align containerResumenR'>
                 Saldo
            </div>
            {
                Object.keys(aportacionesSeparadas).length ?
                    Object.keys(aportacionesSeparadas).map((k, i) => {
                        return (
                            <>
                                <div className='col s2 ft-10 center-align containerResumenBlueDesc' style={{ textTransform: 'capitalize' }}>
                                    {capitalizeFirstLetter(agregaAportacion(k))}
                                </div>
                                <div className='col s2 ft-10 center-align containerResumenBlueDesc'>

                                    {
                                        aportacionesSeparadas[k].length ?
                                            `$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.saldoini, 0)).toFixed(2))}`
                                            : null
                                    }
                                </div>
                                <div className='col s2 ft-10 center-align containerResumenBlueDesc'>

                                    {
                                        aportacionesSeparadas[k].length ?
                                            `${parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.aportaciones, 0)) >= 0 ? '' : ''}$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.aportaciones, 0)).toFixed(2))}`
                                            : null
                                    }
                                </div>
                                <div className='col s2 ft-10 center-align containerResumenBlueDesc'>
                                    {
                                        aportacionesSeparadas[k].length ?
                                            negativos(`$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.rendimiento, 0)).toFixed(2))}`)
                                            : null
                                    }
                                </div>
                                <div className='col s2 ft-10 center-align containerResumenBlueDesc'>
                                    {
                                        aportacionesSeparadas[k].length ?
                                            negativos(`$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.retiros, 0)).toFixed(2))}`)
                                            : null
                                    }
                                </div>
                                <div className='col s2 ft-10 center-align containerResumenRDesc'>
                                    {
                                        aportacionesSeparadas[k].length ?
                                            `${parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.saldo, 0)) >= 0 ? '' : ''}$${nWithCommas(parseFloat(aportacionesSeparadas[k].reduce((accumulator, item) => accumulator + item.saldo, 0)).toFixed(2))}`
                                            : null
                                    }

                                </div>
                            </>
                        )
                    })


                    : null
            }


            <div className='col s4 offset-s8 white-text mt-1  left-align' style={{ padding: '0px' }}>

                <div style={{ background: '#ff606b', height: '25px', paddingTop: '1px', fontWeight: '600', paddingLeft: '10px' }}>
                    <span className='boldText left'>Total de Mi Fondo: </span> <span style={{ marginRight: '10px' }} className='right'>${nWithCommas(parseFloat(parseFloat(getTotalFondo())).toFixed(2))}</span>
                </div>
            </div>

            <div className='col s4 offset-s8 white-text mt-1  left-align' style={{ padding: '0px' }}>

                <div style={{ background: '#ff606b', height: '25px', paddingTop: '1px', fontWeight: '600', paddingLeft: '10px' }}>
                    <span className='boldText left'>Menos Préstamos: </span> <span style={{ marginRight: '10px' }} className='right'>${nWithCommas(parseFloat(saldosFecha.SaldoPrestamos).toFixed(2))}</span>
                </div>
            </div>

            <div className='col s4 offset-s8 white-text mt-1  left-align' style={{ padding: '0px' }}>

                <div style={{ background: '#ff606b', height: '25px', paddingTop: '1px', fontWeight: '600', paddingLeft: '10px' }}>
                    <span className='boldText left'>Saldo Total: </span> <span style={{ marginRight: '10px' }} className='right'>${nWithCommas(parseFloat(saldosFecha.TotalLiquidar).toFixed(2))}</span>
                </div>
            </div>


            <div className='col s12 mt-1'></div>
            <div className='col s4' style={{ paddingLeft: '0px' }}>
                <div className='boldText white-text' style={{
                    background: '#ffa400',
                    paddingLeft: '3px'
                }}>
                    Movimientos de Mi Fondo
                </div>
            </div>
            <div className='col s8'>
            </div>

            <div className='col s12 mt-1'></div>
            <div className='col s3 ft-10 center-align containerResumenBlue'>
                Fecha
            </div>
            <div className='col s3 ft-10 center-align containerResumenBlue'>
                Cuenta
            </div>
            <div className='col s3 ft-10 center-align containerResumenBlue'>
                Tipo de Movimiento
            </div>
            <div className='col s3 ft-10 center-align containerResumenBlue'>
                Monto
            </div>

            {
                movimientos && movimientos.length ?
                    movimientos.slice(0, 7).map((d, j) => {
                        return (
                            <React.Fragment key={j}>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {processFechaGuion(d.Fecha)}
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc' style={{textTransform:'capitalize'}}>
                                    {
                                        capitalizeFirstLetter(d.descripPDF)
                                    }
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {d.Concepto}
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo' ? '-' : ''}   {negativos(`$${nWithCommas(parseFloat(d.Monto).toFixed(2))}`)}
                                </div>
                            </React.Fragment>)
                    })

                    : null
            }


            {
                movimientos.length > 7 ?

                    movimientos.slice(7).map((d, j) => {

                        if (j % 20 === 0) {
                            return (
                                <>
                                    <div className='col s12 html2pdf__page-break'></div>
                                    <div className='col s12' style={{marginTop:'0.5rem'}} ></div>
                                    <div className='col s4'>
                                        <img src={logo} alt="logo" style={{ maxHeight: '55px' }} />
                                    </div>
                                    <div className='col s8'>
                                        <div className='row'>
                                            <div className='col s12 center-align' style={{
                                                background: '#ffa400',
                                                fontWeight: 'bold',
                                                fontSize: '18px',
                                                color: 'white', height: '55px', paddingTop: '1rem'
                                            }}>
                                                Fondo de Ahorro
                                            </div>
                                            <div className='col s12 center-align ft-12'>
                                                Valores Mexicanos Casa de Bolsa, S.A. de C.V.
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col s4' style={{ paddingLeft: '0px' }}>
                                        <div className='boldText white-text' style={{
                                            background: '#ffa400',
                                            paddingLeft: '3px'
                                        }}>
                                            Movimientos de Mi Fondo
                                        </div>
                                    </div>
                                    <div className='col s8'>
                                    </div>

                                    <div className='col s12 mt-1'></div>

                                    <div className='col s3 ft-10 center-align containerResumenBlue'>
                                        Fecha
                                    </div>
                                    <div className='col s3 ft-10 center-align containerResumenBlue'>
                                        Cuenta
                                    </div>
                                    <div className='col s3 ft-10 center-align containerResumenBlue'>
                                        Tipo de Movimiento
                                    </div>
                                    <div className='col s3 ft-10 center-align containerResumenBlue'>
                                        Monto
                                    </div>

                                </>
                            )
                        }

                        return (
                            <React.Fragment key={j}>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {processFechaGuion(d.Fecha)}
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc' style={{textTransform:'capitalize'}}>
                                    {
                                         capitalizeFirstLetter(d.descripPDF)
                                    }
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {d.Concepto}
                                </div>
                                <div className='col s3 ft-10 center-align containerResumenBlueDesc'>
                                    {eliminarAcentos(d.Concepto.toLowerCase()) === 'prestamo' ? '-' : ''}   {negativos(`$${nWithCommas(parseFloat(d.Monto).toFixed(2))}`)}
                                </div>
                            </React.Fragment>)
                    })



                    : null
            }

            <div className='col s12 html2pdf__page-break'></div>
            <div className='col s12'>
                <span className='boldText tituloNota'>Nota: </span>
                <p className='notaText mt-0' style={{marginBottom:'0px'}}>Si tienes alguna duda con respecto a este estado de beneficios o al funcionamiento del plan, no dudes en consultar el Departamento de Recursos Humanos</p>
            </div>
            <div className='col s12'>
                <span className='boldText tituloNota'>IMPORTANTE: </span>
                <p className='notaText mt-0'>El presente documento es una impresión del sistema Fondos en Línea, cuya licencia ha sido otorgada a Valores Mexicanos Casa de Bolsa, S.A. de C.V.,  (en adelante Valmex) el prestador del servicio de individualización de Fondos, quien ha firmado un contrato para la prestación del mismo con la Empresa mencionada en el presente documento, por lo que se prohíbe su reproducción no autorizada. La información aquí contenida es generada con base en la proporcionada por la Empresa de conformidad con lo pactado con la misma. Cualquier duda o inconformidad sobre la información aquí consignada, deberá de hacerse directamente con la Empresa señalada. Este reporte no constituye en modo alguno el estado de cuenta a que se refiere el artículo 203 de la Ley del Mercado de Valores, el cual es el único documento oficial donde quedan asentadas las operaciones realizadas y la posición de valores administrados al amparo del contrato de intermediación bursátil celebrado entre la Empresa y Valmex.  Cualquier tema relacionado con la información aquí presentada debe acudir con su Empresa. Valmex no se hace responsable por fallas, retrasos, omisiones y/o errores en la información reflejada en el presente documento informativo</p>
            </div>
        </div>
    )
}


export default PdfFondoAhorro;