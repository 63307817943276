import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {useAnalytics} from '../AnalyticsContext'
import { logEvent } from "firebase/analytics";

function Token({ toRedirect, callBack, showReenviar, onchangeCode }) {

    const analytics = useAnalytics();
    const [token, setToken] = useState('');
    const navigate = useNavigate();

    const handleChange = (index, value) => {
        // Verificar si el valor ingresado es un número válido
        const numValue = parseInt(value, 10);
        if (!isNaN(numValue)) {
            // Actualizar el valor del token
            const newToken = token.substring(0, index) + numValue + token.substring(index + 1);
            setToken(newToken);
            // Enfocar en el siguiente input si no es el último
            if (index < 5) {
                const nextInput = document.getElementById(`input-${index + 1}`);
                nextInput.focus();
            }
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace') {
            e.preventDefault();
            // Si se presiona la tecla de retroceso, borrar el contenido del input actual
            const newToken = token.substring(0, index) + token.substring(index + 1);
            setToken(newToken);

            // Enfocar en el input anterior si no es el primero
            if (index > 0) {
                const prevInput = document.getElementById(`input-${index - 1}`);
                prevInput.focus();
            }
        }

        if(onchangeCode){
            onchangeCode();
        }
    };

    const enviarToken = (e) => {
        e.preventDefault();
 
        logEvent(analytics, "Login_verificar_codigo", {
            screen_name: "Login",
        });


        if (toRedirect && toRedirect !== "") {
            navigate(toRedirect)
        } else if (callBack) {
            callBack(token)
        }


    }

    return (
        <form onSubmit={enviarToken}>
            {Array.from({ length: 6 }, (_, index) => (
                <div className='col s2' key={index}>
                    <input
                        key={index}
                        id={`input-${index}`}
                        type="text"
                        className='inputToken'
                        maxLength="1"
                        value={token[index] || ''}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        style={{ width: '30px', marginRight: '5px' }}
                        tabIndex={index + 1}
                        autoFocus={index === 0}
                    />
                </div>
            ))}
            <div className='col s12 mt-1'>
                <button type="submit"
                    disabled={token.length < 6}
                    className="btn btn-webSi-primary waves-effect light">Confirmar</button>
            </div>
            {
                showReenviar ?
                    <div className='col s12 mt-2'>
                        <label className='cursorPointer ft-16'>Reenviar Código</label>
                    </div>
                    : null
            }

        </form>
    );

}

export default Token;